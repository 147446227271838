import React from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useState, useEffect } from 'react';
import { useRemote, minutesIntoReadableFormat } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Eye, Mail, Users, Clock, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper'
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import LoadingIcon from '../../Shared/LoadingIcon'
import { format, lastDayOfMonth, startOfMonth, sub } from 'date-fns';
import { useHistory } from 'react-router-dom';


const AnalyticsUsersList = ({ }) => {
	const remote = useRemote();
	const history = useHistory();

	const [filteredData, setFilteredData] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [loading, setLoading] = useState(true);
	const [metrics, setMetrics] = useState(null)
	const [sortingColumn, setSortingColumn] = useState({ column: "Minutes", ascending: false })
	const [dataDisplaying, setDataDisplaying] = useState(null)
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const monthsAgo = Array.from({ length: 6 }, (_, i) => i).reverse();
		const chartDates = monthsAgo.map(v => sub(new Date(), { months: v }))
		Promise.all(
			chartDates.map(workingDate => {
				const start = startOfMonth(workingDate);
				const end = lastDayOfMonth(workingDate);
				return remote.get(`/analytics/aggregates/sessions?from=${format(start, 'yyyy-MM-dd')}&to=${format(end, 'yyyy-MM-dd')}`)
			})

		).then((results) => {
			const list = [];
			for (var i = 0; i < results.length; i++) {
				list.push({
					name: format(chartDates[i], "MMM yyyy"),
					users: results[i].length
				})
			}

			remote.get("/analytics/aggregates/users").then((u) => {
				const myMetrics = {};
				const totalLogins = u.reduce((acc, curr) => acc + curr.numLogins, 0);
				const totalMinutes = u.reduce((acc, curr) => acc + curr.minutes, 0);
				const mostActiveUser = u.sort((a, b) => b.minutes - a.minutes)[0]
				const allUsers = results.flat().map(c => c.contactId);
				const unique = [...new Set(allUsers)];
				myMetrics.totalUsers = u.length;
				myMetrics.averageSessionInMinutes = isNaN(Math.ceil(totalMinutes / totalLogins)) ? 0 + "m" : minutesIntoReadableFormat(Math.ceil(totalMinutes / totalLogins));
				myMetrics.mostActiveUserName = mostActiveUser && mostActiveUser.contact ? mostActiveUser.contact.firstName + " " + mostActiveUser.contact.lastName : null
				myMetrics.mostActiveUserMinutes = mostActiveUser?.minutes
				myMetrics.loginFrequency = unique.length === 0 ? '-' : Math.ceil(totalLogins / unique.length)

				setUsers(u)
				setMetrics(myMetrics);

			}).then(() => setLoading(false))
		})
	}, [])

	function displayMatricsValues(metrics, property) {
		if (metrics && (metrics[property] === 0 || metrics[property])) {
			return <h1 className='total-metrics-value'>{metrics[property]}</h1>
		} else return <h1>&nbsp;</h1>
	}

	// Sorting
	useEffect(() => {
		sortTable(null)
	}, [users, filteredData])

	const sortTable = (column) => {
		if (column === null) {
			setDataDisplaying(hasSearchTerm ? filteredData : users)
			return
		}
		let tableData = hasSearchTerm ? filteredData : users
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Name":
				tableData.sort((a, b) => {
					const user1 = a.contact.firstName || "z"
					const user2 = b.contact.firstName || "z"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			case "Email":
				tableData.sort((a, b) => {
					const user1 = a.contact.email || "z"
					const user2 = b.contact.email || "z"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			case "Minutes":
				tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
				break;
			case "Watching":
				tableData.sort((a, b) => ascending ? a.watchlists - b.watchlists : b.watchlists - a.watchlists)
				break;
			case "Read":
				tableData.sort((a, b) => ascending ? a.emailsRead - b.emailsRead : b.emailsRead - a.emailsRead)
				break;
			case "PortalViews":
				tableData.sort((a, b) => ascending ? a.portalViews - b.portalViews : b.portalViews - a.portalViews)
				break;
			case "PDFDownloads":
				tableData.sort((a, b) => ascending ? (a.portalPdfDownloads + a.emailPdfDownloads) - (b.portalPdfDownloads + b.emailPdfDownloads) : (b.portalPdfDownloads + b.emailPdfDownloads) - (a.portalPdfDownloads + a.emailPdfDownloads))
				break;
			case "ModelDownloads":
				tableData.sort((a, b) => ascending ? a.dataDownloads - b.dataDownloads : b.dataDownloads - a.dataDownloads)
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}

	// Table data
	const colWidths = ["285px", "285px", "70px", "70px", "70px", "70px", "70px", "70px"];
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")}>
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Email")}>
					Email
					{sortingColumn && sortingColumn.column === "Email" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Email" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<Tooltip label="Minutes">
					<div className='sorting-header' onClick={() => sortTable("Minutes")}>
						<Clock className="cursor-pointer colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Watching">
					<div className='sorting-header' onClick={() => sortTable("Watching")}>
						<Users className="cursor-pointer colour-text-positive-dark" size={16} />
						{sortingColumn && sortingColumn.column === "Watching" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Watching" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Read">
					<div className='sorting-header' onClick={() => sortTable("Read")}>
						<Mail className="colour-text-purple" size={16} />
						{sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<div className='sorting-header' onClick={() => sortTable("PortalViews")}>
						<Eye className="cursor-pointer colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
						<PdfIcon noHover className="cursor-pointer" size={16} />
						{sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
						<XlsIcon noHover className="cursor-pointer" size={16} />
						{sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		}
	];


	const dataMap = (data) =>
		data &&
		data.map((datum, idx) => {
			return (
				<tr key={idx} className="cursor-pointer" onClick={() => history.push(ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/" + datum.contact.id)}>
					{/* User details */}
					<td>{datum.contact.firstName + " " + datum.contact.lastName}</td>
					<td style={{ paddingRight: "1.5rem" }} className="truncate">{datum.contact.email}</td>
					{/* Analytics icons */}
					<td className="text-align-center">{minutesIntoReadableFormat(datum.minutes)}</td>
					<td className="text-align-center">{datum.watchlists}</td>
					<td className="text-align-center">{datum.emailsRead}</td>
					<td className="text-align-center">{datum.portalViews}</td>
					<td className="text-align-center">{datum.emailPdfDownloads + datum.portalPdfDownloads}</td>
					<td className="text-align-center">{datum.dataDownloads}</td>
				</tr>
			);
		});


	return (
		<Page noBanner showScrollbar>
			{/* Modal */}
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="xl" style={{ paddingBottom: "1rem" }}>
						<FlexWrapper gap="xs" direction="column" align="flex-start" >
							<label className='total-metrics-label'>Total Users</label>
							{loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'totalUsers')}
						</FlexWrapper>
						<Tooltip label={metrics && "In the last 6 months"}>
							<FlexWrapper gap="xs" direction="column" align="flex-start" >
								<label className='total-metrics-label'>Avg Login Freq</label>
								{loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'loginFrequency')}
							</FlexWrapper>
						</Tooltip>
						<FlexWrapper gap="xs" direction="column" align="flex-start" >
							<label className='total-metrics-label'>Average Session Time</label>
							{loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'averageSessionInMinutes')}
						</FlexWrapper>
						<Tooltip disabled={!metrics || (metrics && !metrics.mostActiveUserMinutes)} label={metrics && <div style={{ display: "flex", alignItems: "center", gap: 5 }}><Clock className="colour-text-primary" size={14} /> {" " + minutesIntoReadableFormat(metrics.mostActiveUserMinutes)}</div>}>
							<FlexWrapper gap="xs" direction="column" align="flex-start" >
								<label className='total-metrics-label'>Most Active User</label>
								{loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'mostActiveUserName')}
							</FlexWrapper>
						</Tooltip>
					</FlexWrapper>
					<PageGridDivider />
					<PageGridItem style={{ paddingTop: "1.5rem" }}>
						<SearchTermFilter
							size="small"
							width="l"
							placeholder={`Search users ${loading ? "" : "(" + users.length + ")"}`}
							isClearable
							dataSet={users}
							setFilteredData={setFilteredData}
							path={["contact.firstName", "contact.lastName", "contact.email"]}
							hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						/>
					</PageGridItem>
					<div className="table-title">All Users</div>
					<Table
						minWidth={1000}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(dataDisplaying)}
						noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default AnalyticsUsersList;