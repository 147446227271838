import React, { useEffect, useState } from 'react';
import PageGridItem from '../../Shared/Page/PageGridItem';
import DetailTextbox from './DetailTextbox';
import Tooltip from '../../../components/popups/Tooltip';
import FlexWrapper from '../../FlexWrapper';
import Checkbox from '../../../components/Checkbox';


const DisclosureList = ({ data, onPin, pinSelected, selectedNotes, pinnedElements, locatedFootnote, setLocatedFootnote, activeNotes, newSelectedNotes, setNewSelectedNotes }) => {
	const [notesList, setNotesList] = useState([])
	const [noActiveNotes, setNoActiveNotes] = useState(false)
	const [noInactive, setNoInactive] = useState(false)

	useEffect(() => {
		const newList = [...pinnedElements, ...data.filter(n => !selectedNotes.includes(n.id))]
		const foundActive = newList.find(n => (n.id && n.selected) || n.offerNoteId)
		const foundInactive = newList.find(n => n.id && !n.selected)
		setNotesList(newList)
		setNoActiveNotes(foundActive ? false : true)
		setNoInactive(foundInactive ? false : true)
	}, [data, pinnedElements])

	function scrollToSuperscript(id) {
		if (locatedFootnote === id) setLocatedFootnote(null)
		else {
			const element = document.getElementById(id)
			setLocatedFootnote(id)
			element.style.outline = 'solid 2px #0E8EFD'

			element.scrollIntoView({ behavior: "smooth", block: "center" });
			setTimeout(() => {
				setLocatedFootnote(null)
			}, 3000);

			setTimeout(() => {
				element.style.outline = 'solid 2px transparent'
			}, 2000);
		}
	}


	if (activeNotes && noActiveNotes) {
		return <div>No active notes to display.</div>
	}

	if (!activeNotes && noInactive) {
		return <div>No inactive notes to display.</div>
	}

	return notesList?.map(note => {
		const { disclosure, noteText, id, offerNoteId, selected, superscript, footnoteId } = note
		const noteId = id || offerNoteId
		const text = disclosure || noteText
		const pinned = offerNoteId ? true : false

		if (activeNotes) {
			if ((selected && id) || superscript) return (
				<PageGridItem container direction='row' gap='s' style={{ paddingBottom: '1rem' }}>
					<FlexWrapper direction="column" gap="xs" style={{ height: "100%", padding: "0.5rem 0rem" }}>
						<Tooltip placement="left" label={pinSelected === noteId || pinned ? "Unpin" : "Pin"}>
							<span onClick={() => onPin(noteId, pinned)} className={`material-symbols-outlined disclosure-pin-icon ${pinned ? 'pinned-note' : pinSelected === noteId ? "actively-pinning" : ""}`} >keep</span>
						</Tooltip>
						{footnoteId &&
							<Tooltip placement="left" label='Locate note'>
								<div className='foootnote-locator' onClick={() => scrollToSuperscript(footnoteId)}>{superscript}</div>
							</Tooltip>
						}
					</FlexWrapper>
					<DetailTextbox
						value={text}
						update={() => null}
						editMode={false}
						placeholder={'Add notes'}
						minHeight={50}
						resize='vertical'
						className='box-style'
						boxStyle
						fullWidth
						divClassName={(!selected && id) ? 'no-selected-box' : null}
					/>
				</PageGridItem>
			)
		} else {
			if (!selected && id) return (
				<PageGridItem container direction='row' gap='s' style={{ paddingBottom: '1rem' }}>
					<FlexWrapper direction="column" gap="xs" style={{ height: "100%", padding: "0.5rem 0rem" }}>
						<Checkbox checked={selected || (newSelectedNotes.length > 0 && newSelectedNotes.includes(id))}
							onChange={() => setNewSelectedNotes(newSelectedNotes.length > 0 ? newSelectedNotes.includes(id) ? newSelectedNotes.filter(n => !n.id === id) : [...newSelectedNotes, id] : [id])}
							className="delete-icon no-margin"
						/>
					</FlexWrapper>
					<DetailTextbox
						value={text}
						update={() => null}
						editMode={false}
						placeholder={'Add notes'}
						minHeight={50}
						resize='vertical'
						className='box-style'
						boxStyle
						fullWidth
						divClassName={(!selected && id) ? 'no-selected-box' : null}
					/>
				</PageGridItem>
			)
		}
	})
}

export default DisclosureList;