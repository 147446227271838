import React, { useRef, useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import useWindowDimensions, { usePdf } from '../Utils/Utils';
import Button from './Buttons/Button';
import FlexWrapper from '../layouts/FlexWrapper';
import LoadingIcon from './LoadingIcon';

const PDFViewer = ({ id, centered, scale, ticker, template, fullHeight, fullWidth, enableKeyboardNavigation, pageOverride, setTotalPages, blankPage }) => {
	const [page, setPage] = useState(1);
	const [styles, setStyles] = useState({});
	const canvasRef = useRef(null);

	const { width } = useWindowDimensions();
	const isMobile = width < 1000;

	const pdfTrigger = usePdf({
		path: `/server-preview`,
		postBody: {
			template: template,
			tickerGroup: ticker
		},
		page,
		canvasRef,
		scale: scale,
		width: "100%",
		height: null,
	});

	const totalPages = pdfTrigger?.pdfDocument?.numPages || 1;

	useEffect(() => {
		if (enableKeyboardNavigation) {
			document.addEventListener('keydown', handleKeyboardNavigation);
			return () => {
				document.removeEventListener('keydown', handleKeyboardNavigation);
			};
		}
	}, [pdfTrigger]);

	useEffect(() => {
		if (totalPages) {
			setTotalPages(totalPages)
		}
	}, [totalPages])

	useEffect(() => {
		const canvas = canvasRef.current;

		if (isMobile) {
			canvas.addEventListener('touchstart', handleTouchStart);
			canvas.addEventListener('touchend', handleTouchEnd);

			return () => {
				canvas.removeEventListener('touchstart', handleTouchStart);
				canvas.removeEventListener('touchend', handleTouchEnd);
			};
		}
	}, [isMobile, page, totalPages]);

	useEffect(() => {
		if (pageOverride) {
			setPage(pageOverride);
		}
	}, [pageOverride]);

	useEffect(() => {
		let styleSetup = {};
		if (centered) {
			styleSetup.marginLeft = 'auto';
			styleSetup.marginRight = 'auto';
			styleSetup.marginTop = '1rem';
		}
		if (!isMobile) {
			styleSetup.width = 'fit-content';
		}
		if (fullHeight) {
			styleSetup.height = '100%';
		}
		if (fullWidth) {
			styleSetup.width = '100%';
		}
		setStyles(styleSetup);
	}, [isMobile]);


	let touchStartX = 0;
	let touchEndX = 0;

	const handleTouchStart = (e) => {
		touchStartX = e.touches[0].clientX;
	};

	const handleTouchEnd = (e) => {
		touchEndX = e.changedTouches[0].clientX;

		const swipeDistance = touchEndX - touchStartX;
		const minSwipeDistance = 50;

		if (swipeDistance > minSwipeDistance) {
			if (page > 1) {
				setPage(page - 1);
			}
		} else if (swipeDistance < -minSwipeDistance) {
			if (page < totalPages) {
				setPage(page + 1);
			}
		}
	};

	const handleKeyboardNavigation = (e) => {
		if (e.key === 'ArrowLeft') {
			if (page > 1) {
				setPage(page - 1);
			}
		} else if (e.key === 'ArrowRight') {
			if (page < totalPages) {
				setPage(page + 1);
			}
		}
	};

	return (
		<div style={styles} id='canvas-wrapper'>
			{!pageOverride && <FlexWrapper gap="m" className="mb-s mt-s" justify="center">
				<Button
					variant="transparent"
					className={'btn-50'}
					disabled={page <= 1}
					onClick={() => setPage(page - 1)}
				>
					<ChevronLeft />
					Prev
				</Button>
				<div style={{ width: '60px', textAlign: 'center' }}>
					{`${page}/${totalPages}`}
				</div>
				<Button
					variant="transparent"
					className={'btn-50'}
					disabled={page >= totalPages}
					onClick={() => setPage(page + 1)}
				>
					Next
					<ChevronRight />
				</Button>
			</FlexWrapper>}
			{pdfTrigger.loadingPDF
				? <div style={{ display: 'flex', alignItems: 'center', width: '362px', height: '485px', marginBottom: "32px", transform: 'translateY(-20%)' }}>
					<LoadingIcon centered size={50} />
				</div>
				: blankPage ? <div></div> : <canvas className="shadow-default" ref={canvasRef} />
			}
		</div>
	);
};

export default PDFViewer;
