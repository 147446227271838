

class RemoteV2 {

    constructor() {
        this.baseOptions = {'credentials':'same-origin'};
        this.base = '/api/';
    }

    registerLogoutHandler(logout) {
        // console.log("Remote V@ has registered a logout handler")
        this.registeredLogoutAction = logout;
    }

    logout() {
        if (this.registeredLogoutAction) {
            this.registeredLogoutAction();
        }
    }

    handleSessionTimeout() {
       if (this.registeredLogoutAction){
            this.registeredLogoutAction();
        }   
    }

    async get(apiURL, options) {
        options = options || {};
        const url = this.base + apiURL;
        const resp = await fetch(url, this.baseOptions);
        return this.handleResponse(resp, options);
    }

    put(apiURL, content, options) {
        return this.send("put", apiURL, content, options);
    }

    post(apiURL, content, options) {
        return this.send("post", apiURL, content, options);
    }

    delete(apiURL, content, options) {
        return this.send("delete", apiURL, content, options);
    }
    
    async send(method, apiURL, content, options) {
        options = options || {};
        const url = this.base + apiURL;
        
        const headers = {
            headers: {}
        };

        headers.headers["Content-Type"] = "application/json; charset=utf-8";
        Object.assign(headers, {
            method: method,
            body: JSON.stringify(content),
            credentials: "same-origin"
        });

        const resp = await fetch(url, headers);
        return this.handleResponse(resp, options);
    }

    handleResponse(resp, options) {
		if (resp.ok) {
            //this.setToken(resp);
            if (options.rawResponse)
                return resp;
            else 
                return resp.json();
        } else {
            if (resp.status === 418 || resp.status === 401) {
                this.handleSessionTimeout();
                return Promise.reject(resp.status);
            } else {
                //Update the user token regardless
                //this.setToken(resp);
            }
            return Promise.reject(resp.status);
        }
    }
}

export default RemoteV2;