import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../components/Buttons/Button';
import { CornerDownLeft, Mail, RefreshCw, Trash, User, UserX, ChevronUp, ChevronDown } from 'react-feather';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Table from '../../../components/Table/Table';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import useWindowDimensions, { formatDate, useRemote, useStorage } from '../../../Utils/Utils';
import SearchTermMuiltiTableFilter from '../../../components/Input/SearchTermMultiTableFilter';
import { toast } from '../../../components/popups/Toast';
import { fromUnixTime } from 'date-fns';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import LoadingIcon from '../../../components/LoadingIcon';
import { useHistory } from 'react-router-dom';



const SuppressionList = ({ }) => {

	const CRAIGS_SUPPRESSION_LIST = 17360;	//FIXME hardcoded Craigs suppression key

	const remote = useRemote()
	const storage = useStorage()
	const history = useHistory()
	const { width } = useWindowDimensions()

	const [bouncesLoading, setBouncesLoading] = useState(true)
	const [unsubscribesLoading, setUnsubscribesLoading] = useState(true)

	const [bounces, setBounces] = useState([])
	const [unsubscribes, setUnsubscribes] = useState([])
	const [users, setUsers] = useState([])

	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [filteredUnsubscribesData, setFilteredUnsubscribesData] = useState([])
	const [filteredBuncesData, setFilteredBuncesData] = useState([])

	const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })
	const [unsubscribesDisplaying, setUnsubscribesDisplaying] = useState([])
	const [buncesDisplaying, setBuncessDisplaying] = useState([])


	useEffect(() => {
		getBounces();
		storage.getOrFetch("/crm/contacts").then(setUsers)
	}, []);

	const getBounces = () => {
		setBouncesLoading(true);
		setUnsubscribesLoading(true);
		remote.get("/crm/suppressions/bounce").then((b) => { setBounces(b); setBouncesLoading(false); });
		remote.get("/crm/unsubscribes/" + CRAIGS_SUPPRESSION_LIST).then((fetchedUnsubscribes) => {   //hardcoded to Craigs! FIXME
			const newUnsubscribesArray = fetchedUnsubscribes.map(u => { return { email: u } })
			setUnsubscribes(newUnsubscribesArray)
			setUnsubscribesLoading(false)
		})
	}

	// user
	const findUser = useMemo(() => (email) => {
		return users.filter(u => !u || !u.email || u.email.localeCompare(email) === 0);
	}, [users])

	const displayUser = (email) => {
		const user = users.find(u => u.email === email);
		if (user) {
			return <>{user.firstName + " " + user.lastName} <span className="extension-label ml-s">{user.email}</span>{" "}</>
		} else {
			return <>User not found <span className="extension-label ml-s">{email}</span>{" "}</>
		}
	}

	// action buttons
	const removeUserFromSuppression = (user, list) => {
		if (list === 'bounce') {
			remote.delete(`/crm/suppressions/bounce/${user.id}`).then(setBounces).catch((e) => toast("Error removing bounce: " + e));
		} else {
			remote.delete(`/crm/unsubscribes/${CRAIGS_SUPPRESSION_LIST}/${user.id}`).then(setUnsubscribes).catch((e) => toast("Error removing unsubscribe: " + e));
		}
	}

	const actions = (email, list) => {
		const user = findUser(email);
		if (user && user.length > 0 && user[0].email) {
			const u = user[0];
			return <>
				<TableActionIcon icon={<Trash />}
					toolTip="Remove user from suppression"
					onClick={(e) => {
						e.stopPropagation();
						removeUserFromSuppression(u, list);
					}} />
				<TableActionIcon icon={<User />}
					toolTip="View user details"
					onClick={(e) => {
						e.stopPropagation();
						history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + u.id);
					}} />
			</>
		} else {
			return null;
		}
	}

	// sort tables
	useEffect(() => {
		sortTable(null)
	}, [unsubscribes, filteredUnsubscribesData, bounces, filteredBuncesData])

	const sortTable = (column) => {
		if (column === null) {
			setUnsubscribesDisplaying(hasSearchTerm ? filteredUnsubscribesData : unsubscribes)
			setBuncessDisplaying(hasSearchTerm ? filteredBuncesData : bounces)
			return
		}
		let unsubscribesTableData = hasSearchTerm ? filteredUnsubscribesData : unsubscribes
		let buncesTableData = hasSearchTerm ? filteredBuncesData : bounces

		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Name":
				buncesTableData.sort((a, b) => ascending ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email))
				unsubscribesTableData.sort((a, b) => ascending ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email))
				break;
			case "Date":
				buncesTableData.sort((a, b) => ascending ? a.created - b.created : b.created - a.created)
				break;
			case "Reason":
				buncesTableData.sort((a, b) => {
					const user1 = a.reason || "-"
					const user2 = b.reason || "-"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setUnsubscribesDisplaying(unsubscribesTableData)
		setBuncessDisplaying(buncesTableData)
	}

	// Tables content
	const colWidths = ["400px", "120px", "400px", "90px"]
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Date")} >
					Date
					{sortingColumn && sortingColumn.column === "Date" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Date" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Reason")} >
					Reason
					{sortingColumn && sortingColumn.column === "Reason" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Reason" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		""
	]

	const bouncesTable = buncesDisplaying.map((b, idx) => <tr
		key={b.email}>
		<td>{displayUser(b.email)}</td>
		<td>{formatDate(fromUnixTime(b.created), "dd/MM/yyyy")}</td>
		<td style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>{b.reason}</td>
		<td className="text-align-right">{actions(b.email, 'bounce')}</td>
	</tr>);

	const unsubscribeTable = unsubscribesDisplaying.map((u, idx) => {
		const user = findUser(u.email);
		if (user.length > 0 && user[0].email) return (
			<tr
				key={u.email} >
				<td>{displayUser(u.email)}</td>
				<td>-</td>
				<td>-</td>
				<td className="text-align-right">{actions(u.email, CRAIGS_SUPPRESSION_LIST)}</td>
			</tr>)
		else return ""
	})

	return (
		<Page fixedBanner id="crm-suppressions-page">
			{/* Old mailing list page below */}
			{/* <CraigsMailingListPage /> */}
			<PageContentBanner divider gap="m">
				{/* <Button icon={<UserPlus />} onClick={() => setModalShowing(true)}>Create User</Button> */}
				<Button icon={<RefreshCw />} onClick={getBounces} >{width < 900 ? "" : "Refresh"}</Button>
				{/* <SelectSearchField size="small" width="m" placeholder="Search lists" isClearable /> */}
				<SearchTermMuiltiTableFilter
					size="small"
					width="m"
					placeholder="Search lists"
					isClearable
					path={["reason", "created", "email"]}
					data={[{ dataSet: bounces, setFilteredData: setFilteredBuncesData }, { dataSet: unsubscribes, setFilteredData: setFilteredUnsubscribesData }]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem col="1 / span 6">
					<DetailSectionHeader header="Manual Unsubscribes" icon={<UserX />} />
				</PageGridItem>
				<PageGridItem colSpan="12">
					{unsubscribesLoading ? <LoadingIcon centered size={50} /> : <Table minWidth={1000} tableStyle={{ maxWidth: 1200 }} colWidths={colWidths} headers={headers} dataMap={unsubscribeTable} />}
				</PageGridItem>
				<PageGridItem col="1 / span 6">
					<DetailSectionHeader header="Bounces" icon={<><Mail /><CornerDownLeft /></>} />
				</PageGridItem>
				<PageGridItem colSpan="12">
					{bouncesLoading ? <LoadingIcon centered size={50} /> : <Table minWidth={1000} tableStyle={{ maxWidth: 1200 }} colWidths={colWidths} headers={headers} dataMap={bouncesTable} />}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default SuppressionList;