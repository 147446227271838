import React, { useEffect, useState } from 'react';
import { FileText } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailFieldList from '../../Shared/DetailSection/DetailFieldList';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';


const TemplateGeneralDetails = ({ editMode, template, setTemplate, /*assetType, setAssetType*/ }) => {
	const [templateType, setTemplateType] = useState(null)
	
	const TemplateTypesList = [ // Needs to be pull from backend at some point 
		{ label: 'Equities Individual Note', value: 'Equities Individual Note' },
		{ label: 'Equities Sector Note', value: 'Equities Sector Note' },
		{ label: 'Strategy Note', value: 'Strategy Note' },
		{ label: 'Unlisted Note', value: 'Unlisted Note' },
		{ label: 'Quarterly Fund Update', value: 'Quarterly Fund Update' },
		{ label: 'Fund Fact Sheet', value: 'Fund Fact Sheet' },
		{ label: 'Product Disclosure Statement (PDS)', value: 'Product Disclosure Statement (PDS)' },
		{ label: 'Statement of Investment Policy and Objectives (SIPO)', value: 'Statement of Investment Policy and Objectives (SIPO)' },
		{ label: 'Letters', value: 'Letters' },
		{ label: 'Blank', value: 'Blank' }
	]   


	useEffect(() => {
		if (template && template.ticker && template.ticker.templateType) setTemplateType({ label: template.ticker.templateType, value: template.ticker.templateType})
		else setTemplateType({ label: "-", value: "-" })
	}, [template])

	function updateTemplate(key, value) { // TODO: create endpoint to actually modify the template
		const updatedTemplate = { ...template, [key]: value }
		setTemplate(updatedTemplate)
	}

	function updateTemplateType(option) {
		const newTickerObj = { ...template.ticker, templateType: option.label }
		updateTemplate('ticker', newTickerObj)
		setTemplateType(option);
	}
	

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Template Details" icon={<FileText />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField bold editMode={editMode} label="Name" value={template.name} onChange={(e) => updateTemplate('name', e.target.value)} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<SelectDetailField options={TemplateTypesList} editMode={editMode} label="Template Type" value={templateType} onChange={(option) => updateTemplateType(option)} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField noClearable dropDown editMode={editMode} dropDownOptions={[{ name: 'Yes' }, { name: 'No' }]} label="Optional Pages" value={template.optionalPages ? "Yes" : "No"} onChange={(option) => updateTemplate('optionalPages', option.value === 'Yes' ? true : false)} />
			</PageGridItem>
			{template.outputs && <PageGridItem col="1 / span 3">
				<DetailFieldList label="Outputs" value={template.outputs.map(output => output.name + (output.dflt ? " (default)" : ""))} />
			</PageGridItem>}
		</>
	);
}

export default TemplateGeneralDetails;