import React, { Component } from "react";
import { Check } from "react-feather";
import "./Checkbox.scss";
import LoadingIcon from "./LoadingIcon";
import Tooltip from "./popups/Tooltip";

class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		if (this.props.disabled) return null
		else if (this.props.onChange && !this.props.disabled && !this.props.loading) {
			e.stopPropagation()
			this.props.onChange({
				currentTarget: {
					name: this.props.name,
					checked: !this.props.checked,
					value: this.props.value,
					type: "checkbox",
				},
			});
		}
	}

	render() {
		const { label, checked, loading, className, disabled, tooltip, tooltipInnerClassName, tooltipClassName } = this.props;
		return (
			<Tooltip label={tooltip} disabled={!tooltip} className={tooltipClassName} innerClassName={tooltipInnerClassName}>
				<div onClick={loading ? null : this.onClick} className={`checkbox-field-wrapper${className ? ' ' + className : ''}${disabled ? ' checkbox-disabled' : ''}${checked ? " checkbox-field-wrapper-checked" : ""}`} >
					{loading ? (
						<LoadingIcon size="16" />
					) : (
						<div className="checkbox-field-box">
							<Check onClick={this.onClick}/>
						</div>
					)}
					{label}
				</div>
			</Tooltip>
		);
	}
}

export default Checkbox;