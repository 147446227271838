import React, { useEffect, useRef } from 'react';
import Checkbox from '../../Checkbox';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';
import { withData } from "../../../DataController";
import VersionOption from '../Versions/VersionOption';
import GroupedVersions from '../Versions/GroupedVersions'
import FlexWrapper from '../../../layouts/FlexWrapper';
import { Lock, Send } from 'react-feather';
import FilterIcon from '../../Icons/FilterIcon';
import { useState } from 'react';
import ShowMoreButton from '../../Shared/ShowMoreButton';
import { useRemote } from '../../../Utils/Utils'
import { format, parseISO, isWithinInterval, subHours, isSameHour, isBefore } from 'date-fns';

const VersionFilters = ({ filterPublished, filterLocked, onPublishedClick, onLockedClick }) => {
	return (
		<FlexWrapper align="center" gap="m">
			<FilterIcon colour="positive" onClick={onPublishedClick} toolTip="Filter by published" active={filterPublished}>
				<Send size={16} className="colour-text-positive-dark" />
			</FilterIcon>
			<FilterIcon colour="primary" onClick={onLockedClick} toolTip="Filter by locked" active={filterLocked}>
				<Lock size={16} className="colour-text-accent-2" />
			</FilterIcon>
		</FlexWrapper>
	);
}

const VersionsSidebarExtension = ({ slideIn, slideOut, template, ticker, data }) => {
	const [filterPublished, setFilterPublished] = useState(false);
	const [filterLocked, setFilterLocked] = useState(false);
	const [showMore, setShowMore] = useState(false);

	const [versions, setVersions] = useState([]);
	const [filteredVersions, setFilteredVersions] = useState([]);
	const [versionsToLoad, setVersionsToLoad] = useState(5);
	const [lastSaved, setLastSaved] = useState(null);
	const [lastSavedSize, setLastSavedSize] = useState(null);
	const [currentUser, setCurrentUser] = useState({});
	const remote = useRemote();

	const currentVersionsToLoad = useRef();
	// remember the latest number of versions to load
	useEffect(() => {
		currentVersionsToLoad.current = versionsToLoad;
	}, [versionsToLoad]);
  
	const filterByLockedAndPublished = () => {
		let filteredItems = versions.filter((version) => {
			if (filterLocked) {
				if (version.locked === false) {
					return false;
				}
			}
			if (filterPublished) {
				if (version.publishedDocument === 0) {
					return false;
				}
			}
			return true;
		})
		setFilteredVersions(groupVersionsByHour(filteredItems))
	}

	useEffect(() => {
		filterByLockedAndPublished()
	}, [versions, filterLocked, filterPublished])

	const groupVersionsByHour = (listOfIndividualVersions) => {
		let groupedListToReturn = []

		listOfIndividualVersions.forEach((nextVersion) => {

			// if groupedList is empty push first version, 
			// second in array should not convert first to an array, so push that as well
			if (groupedListToReturn.length < 2 ) {
				groupedListToReturn.push(nextVersion)
				return;
			}

			// get most recent grouped list element
			// if most recent is an array, get first element
			let groupedListLength = groupedListToReturn.length
			let lastInGroupedList = groupedListToReturn[groupedListLength - 1]
			let versionToCheckAgainst = Array.isArray(lastInGroupedList) 
				? lastInGroupedList[0] 
				: lastInGroupedList

			// check if grouped list element occurred within an hour before current version
			let latestTimeInGroupedList = parseISO(versionToCheckAgainst.savedTime)
			let nextTime = parseISO(nextVersion.savedTime)


			// if yes, add to groupedListToReturn else push to groupedListToReturn
			if(isSameHour(nextTime, latestTimeInGroupedList) && !isSameHour(nextTime, new Date())) {
				// either push to groupedListToReturn nested array or replace latest value with an array consisting of both elements
				if(Array.isArray(lastInGroupedList)) {
					groupedListToReturn[groupedListLength-1].push(nextVersion)
				} else {
					groupedListToReturn[groupedListLength-1] = [lastInGroupedList, nextVersion]
				}
			} else {
				groupedListToReturn.push(nextVersion)
			}
		})
		
		return groupedListToReturn
	}

	const dataChanged = (a, b, c) => {
		setLastSaved(new Date());
		refreshVersions();
		return new Promise((resolve) => resolve(true));

	}

	const intervalPoll = (currentVersionsToLoad) => {
		refreshVersions(currentVersionsToLoad);
	}

	const refreshVersions = (currentVersionsToLoad) => {
		let number = versionsToLoad
		if(currentVersionsToLoad && currentVersionsToLoad.current) {
			number = currentVersionsToLoad.current
		}
		data.getVersions(number).then(v => {
			if (v && v.success) {
				setVersions(v.data)
			}
		});
	}

	const saveSizeListener = (size) => {
		setLastSavedSize(size);
	}

	const loadMore = () => {
		setVersionsToLoad(v => v + 5);
	}
	
	useEffect(() => {
		data.addLocalListener(dataChanged);
		data.setSaveSizeListener(saveSizeListener);
		remote.get("/crm/me").then(data => { setCurrentUser(data.data) });
		
		let timer1 = setInterval(() => intervalPoll(currentVersionsToLoad), 35 * 1000);

		return () => {
			data.removeLocalListener(refreshVersions);
			data.unsetSaveSizeListener();
			clearTimeout(timer1);
		}

	}, [])

	const formatDate = d => {
		return format(parseISO(d), 'dd/MM/yyyy, hh:mm a');
	}

	useEffect(() => {
		refreshVersions();
	}, [ticker, template, versionsToLoad])

	return (
		<SidebarExtensionWrapper noPadding position="right" shadow slideIn={slideIn} slideOut={slideOut} fixedHeader>
			<SideBarSection
				noContentPadding
				header="History"
				filters={
					<VersionFilters
						onLockedClick={() => setFilterLocked(!filterLocked)}
						filterLocked={filterLocked}
						onPublishedClick={() => setFilterPublished(!filterPublished)}
						filterPublished={filterPublished}
					/>
				}
			>
				{lastSaved && <VersionOption id={-1} date={format(lastSaved, 'dd/MM/yyyy, hh:mm:ss a')} author={currentUser.name} size={lastSavedSize} current />}
				{versions && (
					filteredVersions.map((v, idx) => {
						if( Array.isArray(v)) {
							return (
								<GroupedVersions 
									group={v} 
									versionsToLoad={versionsToLoad}
									refresh={() => refreshVersions(currentVersionsToLoad)}
									data={data}
									formatDate={formatDate}
									key={`group-${v[0].id}`}
									current={idx === 0 && lastSaved === null}
								/>
							)
						} else {
							return <VersionOption key={v.id + " " + v.locked}
								id={v.id}
								data={data}
								date={formatDate(v.savedTime)}
								author={v.user}
								size={v.size}
								locked={v.locked}
								published={v.publishedDocument !== 0}
								versionsToLoad={versionsToLoad}
								refresh={() => refreshVersions(currentVersionsToLoad)}
								current={idx === 0 && lastSaved === null}
								/>
						}
					})
					
					)}
				<ShowMoreButton active={showMore} onClick={() => loadMore()} />
			</SideBarSection>
		</SidebarExtensionWrapper>
	);
};

export default withData(VersionsSidebarExtension);