import React, { useEffect, useState } from "react";
import Page from "../Shared/Page/Page";
import DraftsLibraryPageContentBanner from "./DraftsLibraryPageContentBanner";
import DraftsLibraryPageBody from "./DraftsLibraryPageBody";
import { useStorage } from "../../Utils/Utils";
import { Edit3 } from "react-feather";

const DraftsLibrary = () => {
	const [me, setMe] = useState({});
	const [filters, setFilters] = useState({ ticker: null, from: null, to: null });

	const storage = useStorage();

	useEffect(() => {
		storage.getOrFetch("/crm/me")
			.then(setMe)
	}, []);

	return (
		<Page fixedBanner id="drafts-library-page" style={{paddingLeft: "1rem"}}>
			<DraftsLibraryPageContentBanner filters={filters} setFilters={setFilters} header="Drafts" icon={<Edit3 color="#0E8EFD"/>} />
			<DraftsLibraryPageBody me={me} filters={filters} />
		</Page>
	);
}

export default DraftsLibrary;
