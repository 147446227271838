import React, { useEffect } from 'react';
import "./OverlayWrapper.scss";

const OverlayWrapper = ({ children, alignItems, onClick, onKeyDown, onMouseOver, style, overlayClass }) => {

	useEffect(() => {
		if (onKeyDown) {
			document.addEventListener("keydown", onKeyDown )
			return document.removeEventListener("keydown", (e) => onKeyDown(e))
		}
		if (onMouseOver) {
			document.addEventListener("mouseover", onMouseOver)
			return document.removeEventListener("mouseover", (e) => onMouseOver(e))
		}
	}, [])
	
	return (
		<div onClick={onClick} onMouseOver={onMouseOver} onKeyDown={onKeyDown} className={`overlay-wrapper${overlayClass ? ' ' + overlayClass : ''}`} style={{ ...style, alignItems: alignItems === "top" ? "start" : alignItems }}>
			{children}
		</div>
	);
}

export default OverlayWrapper;