import React, { useEffect, useState } from "react";
import { useRemote } from '../../../Utils/Utils'
import SelectTagField from '../../../components/Input/SelectTagField';


const SpotCheckActions = (props) => {
    const { templateId, action, setSelectedPublishActions, label } = props
	const remote = useRemote()
    const fakeOptions1 = [{ value: 'Kate Roberts', email: 'fake@gmail.com', label: 'Kate Roberts' }, { value: 'Tanner Wall', email: 'fake@gmail.com', label: 'Tanner Wall' }]
    const fakeOptions2 = [{ value: 'Provider1', label: 'Provider1' }, { value: 'Provider2', label: 'Provider2' }, { value: 'Provider3', label: 'Provider3' }]
    const fakeOptions3 = [{ value: 'Vanhelsing@gmail.com', label: 'Vanhelsing@gmail.com' }, { value: 'Jonathanharker@outlook.com', label: 'Jonathanharker@outlook.com' }]
    const [options, setOptions] = useState([])
    const [currentValues, setCurrentValues] = useState([])

    useEffect(() => {
        setOptions(label === 'Providers' ? fakeOptions2: label === 'Email Recipiants' ? fakeOptions3 : fakeOptions1) // TODO: create endpoint to retrive real options for depending on the action.stepId
    }, [])

	function update() { // TODO: wire when params are given to the new actions that use this component
		const paramName = action.params[0].name
        // we might need to stringify the current values depending on the data format
		remote.put(`/crm/templates/${templateId}/publish-steps/${action.stepId}/${paramName}`, { "value": currentValues }).then(setSelectedPublishActions);
	}

    function onChange(values) {
        setCurrentValues(values)
    }

    return (
        <div className='custom-publish-actions-options-selector'>
            {label && <div className='action-field-label'>{label}</div>}
            <SelectTagField options={options} onChange={onChange} value={currentValues}/>
        </div>
    )
}

export default SpotCheckActions