import React, { useEffect, useState } from 'react';
import { Edit2 } from 'react-feather';
import { onDrop, useRemote } from '../../../Utils/Utils';
import { useLocation } from "react-router";
import { toastWarning } from '../../../components/popups/Toast';
import { ColourSample, DividerWidth, pieChartDataObject, getCategoryChartData, getCategoryChartConfig } from './BrandsStyles'
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Divider from '../../../components/Divider';
import Button from '../../../components/Buttons/Button';
import Checkbox from '../../../components/Checkbox';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import LoadingIcon from '../../Shared/LoadingIcon';
import SideBarExtensionHeader from '../../../components/SideBar/SideBarExtension/SideBarExtensionHeader';
import Dropzone from '../../../components/Dropzone/Dropzone';
import FontAdmin from './FontAdmin';
import TypographyAdmin from './TypographyAdmin';
import DetailField from '../../Shared/DetailSection/DetailField'
import './StyleGuide.scss'
import "../../Shared/Page/PageGridItem.scss"


const StyleGuide = ({ me }) => {
    const remote = useRemote();
    const location = useLocation();
    const companyId = window.location.hostname === 'craigs-test.research.3skye.com' && me && me.companyId === 4 ? 2 : me?.companyId

    const [useDefault, setUseDefault] = useState(false)
    const [useCustom, setUseCustom] = useState(true)
    const [brandColours, setBrandColours] = useState([])
    const [pieChartImage, setPieChartImage] = useState(null)
    const [categoryChartImage, setCategoryChartImage] = useState(null)
    const [tableStyles, setTableStyles] = useState({})
    const [loading, setLoading] = useState(true)
    const [logoUploading, setLogoUploading] = useState(false)
    const [bannerUpload, setBannerUpload] = useState(false)
    const [bannerUploading, setBannerUploading] = useState(false)
    const [logoSrc, setLogoScr] = useState(null)
    const [bannerScr, setBannerScr] = useState(null)
    const [bannerElement, setBannerElement] = useState(null)
    const [bannerHeight, setBannerHeight] = useState(null)
    const [style, setStyle] = useState(null);
    const [details, setDetails] = useState(null);
    const [bannerEdit, setBannerEdit] = useState(false)
    const [logoSettings, setLogoSettings] = useState(null)
    const [bannerSettings, setBannerSettings] = useState(null)
    const [defaultLogoSettings, setDefaultLogoSettings] = useState(null)

    useEffect(() => {
        remote.get('/brand/default').then(b => {
            setStyle(b);
            remote.get(`/brand/${b.id}/details`).then(setDetails);
        });
    }, [])

    useEffect(() => {
        if (bannerElement) {
            if (style && style.bannerSettings) {
                const parsedSettings = JSON.parse(style.bannerSettings)
                setBannerHeight(parsedSettings)
            } else {
                setBannerHeight(bannerElement.clientHeight)
            }
        }
    }, [bannerElement])

    const fontUpdate = (font) => {
        //cheat and just replace details from the remote.
        remote.get(`/brand/${style.id}/details`).then(setDetails);
    }

    useEffect(() => {
        setLoading(true)
        if (details) {
            const colours = details.colours
            const typography = details.typography

            // Pie Chart Configuration
            const pieChartColoursArray = [colours['Palette 1'].value, colours['Palette 2'].value, colours['Palette 3'].value, colours['Palette 4'].value, colours['Palette 5'].value, colours['Palette 6'].value, colours['Palette 7'].value]

            const pieDataConfiguration = {
                width: 360,
                height: companyId === 2 ? 168 : 200,
                styles: { legend: companyId === 2 ? 'body-text' : 'page-sub-header' }, // Warning: pie chart still depends on a template to be generated
                backgroundColour: '#ffffff',
                legendColour: colours['Text'].value,
                colours: pieChartColoursArray,
                title: { value: '' },
                data: { value: companyId === 2 ? '${INVESTMENT_MIX}' : '${WhereGraph}' },
                suppressEmpty: true,
                donutRadius: 0
            }

            const pieChartData = {
                "data": { "data": pieChartDataObject(companyId) },
                "className": "PieChart",
                "dataSuffix": null,
                "configuration": JSON.stringify(pieDataConfiguration)
            };

            // Category Chart configuration
            const chartColourList = {
                axis: companyId === 2 ? '#D6D5D2': "#2B2E34",
                chartBackground: /*companyId === 2 ? '#EDECE8' :*/ '#ffffff',
                grid: companyId === 2 ? '#D6D5D2' : "#ffffff",
                line1: details.colours['Chart Series 1'].value,
                line2: details.colours['Chart Series 2'].value,
            }
            const categoryChartConfig = getCategoryChartConfig(chartColourList, companyId)
            const categoryChartData = getCategoryChartData(categoryChartConfig, companyId)

            // Table configuration
            const tableStyleObject = {
                font: { fontFamily: typography['Paragraph'].familyName, fontSize: `${typography['Paragraph'].size}pt` },
                row1: { color: colours['Table Header Text 1'].value, backgroundColor: colours['Table Header Background 1'].value, label: 'Table Header 1', keyName: 'tableHead1' },
                row2: { color: colours['Table Header Text 2'].value, backgroundColor: colours['Table Header Background 2'].value, label: 'Table Header 2', keyName: 'tableHead2' },
                row3: { color: colours['Table Header Text 3'].value, backgroundColor: colours['Table Header Background 3'].value, label: 'Table Header 3', keyName: 'tableHead3' },
                default: { color: colours['Text'].value, backgroundColor: '#fff', label: 'Table Body Cell', keyName: 'tableCell' }
            }
            const stylesArray = [
                {
                    category: 'General',
                    values: [
                        { ...details.colours['Primary'], keyName: 'primary' },
                        { ...details.colours['Secondary'], keyName: 'secondary' },
                        { ...details.colours['Text'], keyName: 'text' },
                        { ...details.colours['Divider'], keyName: 'divider' },
                    ]
                },
                {
                    category: 'Tables',
                    values: [
                        { ...details.colours['Table Header Text 1'], keyName: 'tableHeaderText1' },
                        { ...details.colours['Table Header Background 1'], keyName: 'tableBackgroundText1' },
                        { ...details.colours['Table Header Text 2'], keyName: 'tableHeaderText1' },
                        { ...details.colours['Table Header Background 2'], keyName: 'tableBackgroundText1' },
                        { ...details.colours['Table Header Text 3'], keyName: 'tableHeaderText1' },
                        { ...details.colours['Table Header Background 3'], keyName: 'tableBackgroundText1' },
                    ]
                },
                {
                    category: 'Pie Chart',
                    values: [
                        { ...details.colours['Palette 1'], keyName: 'palette1' },
                        { ...details.colours['Palette 2'], keyName: 'palette2' },
                        { ...details.colours['Palette 3'], keyName: 'palette3' },
                        { ...details.colours['Palette 4'], keyName: 'palette4' },
                        { ...details.colours['Palette 5'], keyName: 'palette5' },
                        { ...details.colours['Palette 6'], keyName: 'palette6' },
                        companyId === 2? "" : { ...details.colours['Palette 7'], keyName: 'palette7' },
                    ]
                },
                {
                    category: 'Category Chart',
                    values: [
                        { ...details.colours['Chart Series 1'], keyName: 'chartSeries1' },
                        { ...details.colours['Chart Series 2'], keyName: 'chartSeries2' }
                    ]
                },
            ]

            // update states
            setBrandColours(stylesArray)
            setTableStyles(tableStyleObject)

            if (style) {
                setLogoScr(style.logo ? true : false)
                setBannerScr(style.banner ? true : false)
                if (style.bannerSettings) {
                    setBannerSettings(JSON.parse(style.bannerSettings))
                } else setBannerSettings({ height: 'auto', width: '21cm' })
                if (style.logoSettings) {
                    setLogoSettings(JSON.parse(style.logoSettings))
                    setDefaultLogoSettings(JSON.parse(style.logoSettings))
                } else setLogoSettings({ height: 'auto', width: 150 })
            }

            if (pieChartData) {
                remote.post(`ssrender/${companyId === 2 ? 'Simplicity QFU' : 'Kernel Fund Fact Sheet'}`, pieChartData).then(resp => {
                    setPieChartImage(resp.data);
                    const bannerImage = document.getElementById('banner-image-sample');
                    if (bannerImage) {
                        setLoading(false)
                        setBannerElement(bannerImage)
                    }
                }).catch(() => setPieChartImage(null))

                remote.post(`ssrender/${companyId === 2 ? 'Simplicity QFU' : 'Company Note'}`, categoryChartData).then(resp => {
                    setCategoryChartImage(resp.data);
                }).catch(() => setCategoryChartImage(null))
            }
        }

        setLoading(false)
    }, [location.pathname, companyId, details, style])


    const uploadLogo = (files) => {
        onDrop(files, `/brand/logo/${style.id}`, {
            setLoading: setLogoUploading,
            onUploadComplete: (data) => {
                try {
                    const parsed = JSON.parse(data.target.response);
                    setStyle(parsed.data);
                } catch (e) {
                    console.log("Error ", e)
                    toastWarning("Error uploading file")
                }
            },
            maxFileSize: 5 * 1024 * 1024
        })
    }

    const uploadBanner = (files) => {
        onDrop(files, `/brand/banner/${style.id}`, {
            setLoading: setBannerUploading,
            onUploadComplete: (data) => {
                setBannerUpload(false)
                try {
                    const parsed = JSON.parse(data.target.response);
                    setStyle(parsed.data);
                    setBannerScr(parsed.data.banner)
                } catch (e) {
                    console.log("Error ", e)
                    toastWarning("Error uploading file")
                }
            },
            maxFileSize: 5 * 1024 * 1024
        })
    }

    const previewElement = (el, sample) => {
        const styles = {};
        switch (el) {
            case "Banner Header":
                const title = details?.typography["Banner Header"];
                copyStylesFromTypography(styles, title);
                return <div style={styles}>Banner Header</div>
            case "Banner Sub-header":
                const subtitle = details?.typography["Banner Sub-header"];
                copyStylesFromTypography(styles, subtitle);
                return <div style={styles}>Banner Sub-header</div>
            case "Heading 1":
                const h1 = details?.typography["Heading 1"];
                copyStylesFromTypography(styles, h1);
                return <h1 style={styles}>Heading 1</h1>
            case "Heading 2":
                const h2 = details?.typography["Heading 2"];
                copyStylesFromTypography(styles, h2);
                return <h2 style={styles}>Heading 2</h2>
            case "Heading 3":
                const h3 = details?.typography["Heading 3"];
                copyStylesFromTypography(styles, h3);
                return <h3 style={styles}>Heading 3</h3>
            case "Body":
            case "Footer":
                const body = el === "Body" ? details?.typography["Paragraph"] : details?.typography["Footer"];
                copyStylesFromTypography(styles, body);
                return <p style={styles}>{sample || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget dignissim est. Fusce at auctor lacus. Ut et ultrices turpis, in vestibulum lorem. Praesent consectetur lorem eu turpis scelerisque, vitae aliquam tellus pretium. Suspendisse maximus arcu id lorem consectetur pharetra sit amet sit amet nisl. Mauris vulputate sit amet lacus non porttitor."}</p>
        }
    }

    const copyStylesFromTypography = (styleElement, typographyElement) => {
        if (!typographyElement) {
            return;
        }
        styleElement.fontSize = (typographyElement.size || 12) + 'pt';
        styleElement.color = typographyElement.colour
        styleElement.fontFamily = typographyElement.familyName
        if (typographyElement.variant?.includes("Bold")) {
            styleElement.fontWeight = 700;
        } else {
            styleElement.fontWeight = 400;
        }
        if (typographyElement.variant?.includes("Italic")) {
            styleElement.fontStyle = 'italic';
        } else {
            styleElement.fontStyle = 'normal';
        }
    }

    const updateColour = (id, colour, name) => {
        remote.put(`/brand/colour/${details.brandId}/${id}`, { name: name, value: colour }).then(response => {
            const deets = { ...details };
            deets.colours[response.name] = response;
            setDetails(deets);
        })
    }

    const updateImageSettings = (string, sizeObj) => {
        remote.post(`/brand/settings/${string}/${style.id}`, sizeObj).then((resp) => {
            if (string !== 'logo') setBannerEdit(false)
            else setDefaultLogoSettings(sizeObj)
        })
    }


    return (
        <Page noBanner noOverflownY style={{ paddingLeft: '1rem' }}>
            <PageContentBodyGrid splitScroll rowGap='m' gridColCount='4' paddingTop='0rem' subGrid gridTemplateColumns='300px calc(21cm + 1rem)' noOverflownY>
                <PageGridItem container className='second-sidebar-left'>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Settings</div>
                        <Checkbox disabled checked={useDefault} label='Use Default' onChange={() => { setUseDefault(!useDefault); setUseCustom(!useCustom) }} />
                        <Checkbox disabled checked={useCustom} label='Use Custom' onChange={() => { setUseCustom(!useCustom); setUseDefault(!useDefault) }} />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Logo</div>
                        <img style={{ height: 'auto', width: 'fit-content', maxHeight: 100, maxWidth: '100%' }} src={`data:image/png;base64, ${style?.logo}`} alt="logo" id="image-template-logo" />
                        <Dropzone onDrop={uploadLogo} uploading={logoUploading} showUploadingSpinner small />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Logo Width (pt)</div>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <DetailField editMode={true} size='small' value={logoSettings?.width} onChange={(e) => setLogoSettings({ ...logoSettings, width: e.target.value })} />
                            <Button onClick={() => updateImageSettings('logo', logoSettings)} style={{ width: 'fit-content' }} disabled={logoSettings?.width === defaultLogoSettings?.width}>Save</Button>
                        </div>
                    </div>
                    {companyId !== 2 && <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Banner Image</div>
                        <label className='colour-text-label-grey'>{style?.bannerSettings ? `Recommended size ${bannerSettings.width}pt by ${bannerSettings.height}pt` : 'No recommended size added'}</label>
                        {bannerUpload
                            ? <Dropzone onDrop={uploadBanner} uploading={bannerUploading} showUploadingSpinner small />
                            : <img style={{ height: 'auto', width: '198pt' }} src={`data:image/png;base64, ${style?.banner}`} alt="banner image" id="image-template-banner" />}
                        <div style={{ display: 'flex', gap: 10 }}>
                            <Button onClick={() => setBannerUpload(!bannerUpload)} style={{ width: 'fit-content' }}>{bannerUpload ? "Cancel" : "Upload"}</Button>
                            <Button variant='secondary' onClick={() => setBannerEdit(!bannerEdit)} style={{ width: 'fit-content' }}>{bannerEdit ? "Cancel" : "Edit size"}</Button>
                            {bannerEdit && <Button onClick={() => updateImageSettings('banner', bannerSettings)} style={{ width: 'fit-content' }}>Save</Button>}
                        </div>
                        {bannerEdit &&
                            <div style={{ display: 'flex', gap: 10 }}>
                                <DetailField editMode={true} autoFocus size='small' label="width" value={bannerSettings.width} onChange={(e) => setBannerSettings({ ...logoSettings, width: e.target.value })} />
                                <DetailField editMode={true} size='small' label="height" value={bannerSettings.height} onChange={(e) => setBannerSettings({ ...logoSettings, height: e.target.value })} />
                            </div>
                        }
                    </div>}
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Colour Palette</div>
                        {brandColours && brandColours.length > 0 && brandColours.map((obj, index) => {
                            return (
                                <div className='sample-group' key={obj.category + "-" + index}>
                                    <label className='colour-text-label-grey'>{obj.category}</label>
                                    {obj.values.map((val, idx) => {
                                        return (
                                            <ColourSample changeBrandColour={(value) => updateColour(val.id, value, val.name)} colour={val.value} label={val.name} key={`key-${idx}-${val.color}`} />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                    <div className='second-sidebar-left-container'>
                        <FontAdmin brand={style} fontUpdated={fontUpdate} details={details} updateDetails={setDetails} />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Typography</div>
                        <TypographyAdmin brand={style} details={details} updateDetails={setDetails} />
                    </div>
                    {/* Hide dividers settings */}
                    <div className='second-sidebar-left-container' style={{ display: 'none' }}>
                        <div className='second-sidebar-left-label'>Misc</div>
                        {details?.dividers && Object.values(details.dividers).map((val, idx) => <DividerWidth details={details} updateDetails={setDetails} label={val.name} key={`key-${idx}-${val.name}`} />)}
                    </div>
                    <div className='second-sidebar-left-container no-border'>
                        <Button disabled>Save Changes</Button>
                        <Button disabled variant='secondary'>Save as Default</Button>
                    </div>
                </PageGridItem>
                <PageGridItem container className='rest-of-the-page-width-second-sidebar-left'>
                    <PageContentBanner divider gap='m' dividerThinkness='m' style={{ height: 80 }}>
                        <SideBarExtensionHeader header='Style Guide' icon={<Edit2 color='#0E8EFD' />} style={{ marginBottom: 0, paddingRight: 0 }} noTabs />
                        <Divider vertical height='24px' marginTop='0.25rem' />
                        <Button disabled >Save changes</Button>
                        <Button disabled variant='secondary' >Save as Default</Button>
                    </PageContentBanner>
                    {loading ? <LoadingIcon centered /> :
                        <PageContentBodyGrid paddingTop='0px'>
                            <WebfontLoader onStatus={() => { }} >
                                <PageGridItem colSpan='12' >
                                    <div className='sample-content-section'>
                                        <label className='colour-text-label-grey'>Logo + Banner</label>
                                        {bannerScr === null || logoSrc === null ? <LoadingIcon centered /> : bannerScr === true ?
                                            <div id='banner-image-sample' style={{ position: 'relative' }}>
                                                <img src={`data:image/png;base64, ${style?.banner}`} alt='banner-image-sample' className='sample-banner-background' style={{ width: '21cm', height: bannerSettings?.height || bannerHeight }} />
                                                <div className='sample-banner-with-logo' style={{ height: (bannerSettings && bannerSettings.height) || bannerHeight, position: 'absolute' }}>
                                                    <div className='sample-banner-text'>
                                                        {previewElement("Banner Header")}
                                                        {previewElement("Banner Sub-header")}
                                                    </div>
                                                    <img style={{ height: 'auto', width: +logoSettings?.width + 'pt' || '57pt' }} src={`data:image/png;base64, ${style?.logo}`} alt="logo" id="image-template-logo-in-banner-image" />
                                                </div>
                                            </div> :
                                            <div className='sample-banner-with-logo'>
                                                <img style={{ height: 'auto', width: +logoSettings?.width + 'pt' || '57pt' }} src={`data:image/png;base64, ${style?.logo}`} alt="logo" id="image-template-logo-in-banner" />
                                                <div className='sample-banner-text'>
                                                    {previewElement("Banner Header")}
                                                    {previewElement("Banner Sub-header")}
                                                </div>
                                            </div >
                                        }
                                    </div>
                                    <div className={`sample-content-section`}>
                                        <label className='colour-text-label-grey'>Typography</label>
                                        {previewElement("Heading 1")}
                                        {previewElement("Body")}
                                        <div className='sample-headings-two-and-three'>
                                            <div>
                                                {previewElement("Heading 2")}
                                                {previewElement("Body", "Lorem ipsum")}
                                            </div>
                                            <div>
                                                {previewElement("Heading 3")}
                                                {previewElement("Body", "Lorem ipsum")}
                                            </div>
                                        </div>

                                        <label className='colour-text-label-grey'>Footer Text</label>
                                        {previewElement("Footer")}

                                    </div>
                                    <div className='sample-content-section double-column' style={{ paddingBottom: '2.5rem', gap: '2.5rem' }}>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>{companyId === 2 ? 'Bar Chart' : 'Line Chart'}</label>
                                            {categoryChartImage && <img alt="category chart" style={{ width: 450, height: 'auto', marginTop: '-2rem' }} src={"data:image/png;base64," + categoryChartImage} />}
                                        </div>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>Pie Chart</label>
                                            {pieChartImage && <img alt="pie chart" style={{ width: 400, height: 'auto', marginBottom: '-0.5rem' }} src={"data:image/png;base64," + pieChartImage} />}
                                        </div>
                                    </div>
                                    <div className='sample-content-section' style={{ position: 'relative', marginTop: '-1.5rem', borderTop: 'solid 1.5px #E7EAF2', backgroundColor: '#fff' }}>
                                        <label className='colour-text-label-grey'>Table Col/Row</label>
                                        <div className='sample-tables'>
                                            <table className='sample-table' style={tableStyles.font}>
                                                <thead>
                                                    <tr style={tableStyles.row1}>
                                                        <th>Header 1</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={tableStyles.row2}>
                                                        <td>Header 2</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles.row3}>
                                                        <td>Header 3</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles.row4}>
                                                        <td>Header 4</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                   
                                    </div>
                                </PageGridItem>
                            </WebfontLoader>
                        </PageContentBodyGrid>
                    }
                </PageGridItem>

            </PageContentBodyGrid>
        </Page >
    )
}

export default StyleGuide;