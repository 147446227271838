import React, { useEffect, useState } from 'react';
import Button from '../../../components/Buttons/Button';
import { Mail, Plus, ChevronUp, ChevronDown} from 'react-feather';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import IconCounter from '../../../components/IconCounter';
import Table from '../../../components/Table/Table';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import useWindowDimensions, { formatDate, useRemote, undoDeleteDistributionList } from '../../../Utils/Utils';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import { toast, toastDanger, UndoToastMessage } from '../../../components/popups/Toast';
import { useHistory } from 'react-router-dom';



const DistributionList = ({  }) => {

	const remote = useRemote();
	const history = useHistory();
	const { width } = useWindowDimensions();

	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })
	const [dataDisplaying, setDataDisplaying] = useState([])


	useEffect(() => {
		remote.get("/crm/lists")
			.then((lists) => {
				let sortedLists = lists.sort((userA, userB) => {
					let nameA = userA.name
					let nameB = userB.name
					return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
				})
				return sortedLists
			})
			.then(setData);
	}, []);

	// Sort Table
	useEffect(() => {
		sortTable(null)
	}, [data, filteredData])

	const sortTable = (column) => {
		if (column === null) {
			setDataDisplaying(hasSearchTerm ? filteredData : data)
			return
		}
		let tableData = hasSearchTerm ? filteredData : data
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Name":
				tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
				break;
			case "DateCreated":
				tableData.sort((a, b) => ascending ? a.createdDate.localeCompare(b.createdDate) : b.createdDate.localeCompare(a.createdDate))
				break;
			case "Members":
				tableData.sort((a, b) => ascending ? a.members - b.members : b.members - a.members)
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}


	const addListToData = (list) => {
		let listWithoutRemovedList = data.filter((listToFilter) => list.name !== listToFilter.name)
		let listsToSort = [...listWithoutRemovedList, { ...list, listId: list.id }]
		let sortedLists = listsToSort.sort((userA, userB) => {
			let nameA = userA.name
			let nameB = userB.name
			return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
		})
		setData(sortedLists)
	}

	const removeList = (e, list) => {
		e.stopPropagation();
		remote.get("/crm/lists/" + list.listId)
			.then((DistributionListToDelete) => {
				// setList(DistributionListToDelete.data.list); setContacts(DistributionListToDelete.data.contacts); setTickers(DistributionListToDelete.data.tickers)

				remote.delete(`/crm/lists/${list.listId}`)
					.then(() => {
						let newArray = []
						data.forEach((dataList) => {
							if (dataList.listId !== list.listId) {
								newArray.push(dataList)
							}
						})
						setData(newArray)
						toast(<UndoToastMessage message="Distribution List removed" onClick={() => { undoDeleteDistributionList(list, DistributionListToDelete.data.tickers, DistributionListToDelete.data.contacts, addListToData) }} />, { autoClose: 10000 })
					})
					.catch(() => {
						toastDanger("List could not be removed")
					})
			});
	}

	// Table data
	const colWidths = [40 + "%", 30 + "%", 10 + "%", 20 + "%"];
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("DateCreated")} >
					Date Created
					{sortingColumn && sortingColumn.column === "DateCreated" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "DateCreated" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Members")} >
					Members
					{sortingColumn && sortingColumn.column === "Members" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Members" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		""];

	const dataMap = (dataToMap) => dataToMap && dataToMap.map((list, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => history.push(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + list.listId)}>
				<td>
					{/* {list.name}
					<span className="extension-label ml-s">{list.createdBy}</span> */}
					<TickerSectorItem label={list.name} extensionLabel={list.createdBy} image={list.logo} />
				</td>
				<td>
					{list.createdDate && formatDate(list.createdDate) }
				</td>
				<td>
					<IconCounter icon={<Mail />} dataAvailable={true} type="read" count={list.members} />
				</td>
				<TableActionIconGroup
					download={{ hide: true }}
					edit={{ onClick: (e) => { e.stopPropagation(); history.push(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + list.listId + "?edit=true") } }}
					duplicate={{ onClick: (e) => { e.stopPropagation(); history.push(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + list.listId + "?create=true") } }}
					remove={{ onClick: (e) => removeList(e, list) }}
				/>
			</tr>
		);
	});

	return (
		<Page fixedBanner id="crm-distribution-page">
			{/* Old mailing list page below */}
			{/* <CraigsMailingListPage /> */}
			<PageContentBanner divider gap="m">
				{/* <Button icon={<UserPlus />} onClick={() => setModalShowing(true)}>Create User</Button> */}
				<Button icon={<Plus />} onClick={() => history.push(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/create")} >{width < 900 ? "" : "New Distribution List"}</Button>
				{/* <SelectSearchField size="small" width="m" placeholder="Search lists" isClearable/> */}
				<SearchTermFilter
					size="small"
					width="l"
					placeholder="Search lists"
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["createdBy", "createdDate", "name"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<Table minWidth={1000} tableStyle={{ maxWidth: 1200 }} colWidths={colWidths} headers={headers} dataMap={dataMap(dataDisplaying)} />
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default DistributionList;