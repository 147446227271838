import React from 'react';
import TextArea from '../../../components/Input/TextArea'
import './RiskIndicator.scss'

const DetailTextbox = (props) => {
    const { value, update, editMode, placeholder, label, minHeight, resize, boxStyle, boxClassName, divClassName, fullWidth, autoFocus } = props
    return (
        <>
            {label && <div className={`risk-label ${editMode ? '' : 'static-label'}`}>{label}</div>}
            {editMode ?
                <TextArea
                    placeholder={placeholder}
                    className={`detail-text-box${boxClassName ? ' ' + boxClassName : ''}`}
                    value={value}
                    onChange={update}
                    style={{ minHeight: minHeight }}
                    resize={resize}
                    autoFocus={autoFocus}
                /> :
                <div className={`detail-text${divClassName ? ' ' + divClassName : ''}${boxStyle? ' box-style' : ''}${fullWidth ? ' full-width' : ''}`}>{value}</div>
            }
        </>
    )
}

export default DetailTextbox