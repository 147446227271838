import React, { useEffect, useState } from 'react';
import SearchObjectFilter from '../../../components/Input/SearchObjectFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useRemote, useStorage } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Eye, Mail, Monitor, Smartphone, Briefcase, Users, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIcon from '../../../components/LoadingIcon';
import TickerSectorItem from '../../../components/TickerSectorImage'
import { format, parseISO } from 'date-fns';
import { Clock } from 'react-feather';
import { ROUTE_PATHS, PAGES } from '../../../InternalApiApp';
import { minutesIntoReadableFormatWithSeconds } from '../../../Utils/Utils'
import TruncatedString from '../../Shared/TruncatedString'


const AnalyticsUserPage = ({ }) => {
	const remote = useRemote();
	const storage = useStorage()
	const history = useHistory()
	const { userId } = useParams();

	// State
	const [user, setUser] = useState(null);
	const [analytics, setAnalytics] = useState(null);
	const [metrics, setMetrics] = useState(null)
	const [allTickers, setAllTickers] = useState([])
	const [allGroups, setAllGrpups] = useState([])

	const [filteredData, setFilteredData] = useState([]);
	const [filteredList, setFilteredList] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [loading, setLoading] = useState(true);
	const [currentFilter, setCurrentFilter] = useState("");
	const [sortingColumn, setSortingColumn] = useState({ column: "Time/Date", ascending: false })
	const [dataDisplaying, setDataDisplaying] = useState(null)

	useEffect(() => {
		if (!userId) {
			setUser(null)
			setAnalytics(null)
			setLoading(false)
			return;
		}

		Promise.all([
			storage.getOrFetch("/analytics/aggregates/users"),
			remote.get(`/crm/contacts/${userId}`),
			remote.get(`/analytics/users/${userId}`),
			storage.getOrFetch(`/crm/tickers`),
			storage.getOrFetch(`/crm/groupings/${1}`),

		]).then(([fetchedUsers, fetchedUser, fetchedEvents, fetchedTickers, fetchedGroups]) => {
			const foundUser = fetchedUsers.find(u => u.contact.id === +userId)
			const myMetrics = {};

			const unfilteredData = fetchedEvents && fetchedEvents.length > 0 ? fetchedEvents : []
			const filteredAnalytics = unfilteredData && unfilteredData.length > 0 && unfilteredData.filter(d => d.documentViewed || d.modelDownloaded || d.pdfDownloaded)
			const totalTime = filteredAnalytics && filteredAnalytics.reduce((acc, curr) => acc + curr.minutes, 0)
			const portalViews = filteredAnalytics && filteredAnalytics.reduce((acc, curr) => acc + curr.documentViewed, 0)
			const sortedAnalytics = filteredAnalytics && filteredAnalytics.sort((a, b) => b.eventTime.localeCompare(a.eventTime))

			myMetrics.emailViews = foundUser.emailsRead
			myMetrics.portalViews = portalViews
			myMetrics.modelDownload = filteredAnalytics && filteredAnalytics.reduce((acc, curr) => acc + curr.modelDownloaded, 0)
			myMetrics.pdfDownload = filteredAnalytics && filteredAnalytics.reduce((acc, curr) => acc + curr.pdfDownloaded, 0)
			myMetrics.watchlist = foundUser.watchlists
			myMetrics.averageSessionTime = totalTime / portalViews

			setUser(fetchedUser)
			setAnalytics(sortedAnalytics)
			setMetrics(myMetrics)
			setAllTickers(fetchedTickers)
			setAllGrpups(fetchedGroups?.groups)

		}).then(() => setLoading(false))
	}, [userId]);

	// Sorting
	useEffect(() => {
		sortTable(null)
	}, [filteredList, filteredData, hasSearchTerm])

	const sortTable = (column) => {
		if (column === null) {
			setDataDisplaying(hasSearchTerm ? filteredData : filteredList)
			return
		}
		let tableData = hasSearchTerm ? filteredData : filteredList
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Time/Date":
				tableData.sort((a, b) => ascending ? a.eventTime.localeCompare(b.eventTime) : b.eventTime.localeCompare(a.eventTime))
				break;
			case "Ticker/Group":
				tableData.sort((a, b) => ascending ? a.note.ticker.localeCompare(b.note.ticker) : b.note.ticker.localeCompare(a.note.ticker))
				break;
			case "Title":
				tableData.sort((a, b) => {
					const note1 = a.note.title || ""
					const note2 = b.note.title || ""
					return ascending ? note1.localeCompare(note2) : note2.localeCompare(note1)
				})
				break;
			case "Minutes":
				tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
				break;
			case "Download":
				tableData.sort((a, b) => {
					const download1 = a.pdfDownloaded && a.modelDownloaded ? 3 : a.pdfDownloaded && !a.modelDownloaded ? 2 : !a.pdfDownloaded && a.modelDownloaded ? 1 : 0
					const download2 = b.pdfDownloaded && b.modelDownloaded ? 3 : b.pdfDownloaded && !b.modelDownloaded ? 2 : !b.pdfDownloaded && b.modelDownloaded ? 1 : 0
					return ascending ? download1 - download2 : download2 - download1
				})
				break;
			case "ViewedIn":
				tableData.sort((a, b) => {
					const views1 = a.documentViewed ? 1 : 0
					const views2 = b.documentViewed ? 1 : 0
					return ascending ? views1 - views2 : views2 - views1
				})
				break;
			case "Device":
				tableData.sort((a, b) => {
					const device1 = a.info === null ? '-' : a.info && a.info.width < 1000 ? "Mobile" : "Desktop"
					const device2 = b.info === null ? '-' : b.info && b.info.width < 1000 ? "Mobile" : "Desktop"
					return ascending ? device1.localeCompare(device2) : device2.localeCompare(device1)
				})
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}

	// Table data
	const colWidths = ["140px", "180px", "180px", "100px", "100px", "100px", "100px"];
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Time/Date")} >
					Time/Date
					{sortingColumn && sortingColumn.column === "Time/Date" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Time/Date" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Ticker/Group")} >
					Ticker/Group
					{sortingColumn && sortingColumn.column === "Ticker/Group" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Ticker/Group" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Title")} >
					Title
					{sortingColumn && sortingColumn.column === "Title" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Title" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<Tooltip label="Minutes">
					<div className='sorting-header centered' onClick={() => sortTable("Minutes")}>
						<Clock className="cursor-pointer colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<div className='sorting-header centered' onClick={() => sortTable("Download")} >
					Download
					{sortingColumn && sortingColumn.column === "Download" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Download" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			className: "text-align-center"
		},
		{
			text: (
				<div className='sorting-header centered' onClick={() => sortTable("ViewedIn")} >
					Viewed In
					{sortingColumn && sortingColumn.column === "ViewedIn" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "ViewedIn" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			className: "text-align-center"
		},
		{
			text: (
				<div className='sorting-header centered' onClick={() => sortTable("Device")} >
					Device
					{sortingColumn && sortingColumn.column === "Device" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Device" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			className: "text-align-center"
		}
	];

	const dataMap = (data) =>
		data && data.length > 0 && data.map((data, idx) => {
			const { pdfDownloaded, modelDownloaded, documentViewed, info, note } = data;
			return (
				<tr key={idx} className="cursor-pointer" onClick={() => history.push(ROUTE_PATHS[PAGES.ANALYTICS] + `/users/user/${userId}/research/${note.publicationId}`)}>
					{/* User details */}
					<td>{format(parseISO(data.eventTime), 'hh:mmaaa dd/MM/yyyy')}</td>
					<td>
						<div className='ticker-sector-item'>
							<TickerSectorItem size="m" label={note.ticker} image={getImage(note)} onClick={() => null} />
							<div className='tsi-details'><div className='tsi-label'>{note.ticker}</div></div>
						</div>
					</td>
					<td style={{ paddingRight: "1.5rem" }}><TruncatedString string={note.title} /></td>
					<td className="text-align-center">
						{data.minutes === 0 || data.minutes ? data.minutes + "m" : "-"}
					</td>
					<td className="text-align-center">
						<Tooltip disabled={documentViewed} label={pdfDownloaded ? "PDF Download" : modelDownloaded ? "Model Download" : ""}>
							{pdfDownloaded ? <PdfIcon noHover /> : modelDownloaded ? <XlsIcon noHover /> : "-"}
						</Tooltip>
					</td>
					<td className="text-align-center">
						<Tooltip disabled={!documentViewed} label={documentViewed ? "Portal" : ""}>
							{false ? <Mail className="colour-text-purple" /> : documentViewed ? <Eye className="colour-text-primary" /> : "-"}
						</Tooltip>
					</td>
					<td className="text-align-center">
						<Tooltip disabled={!info || !info.width} label={info?.width < 1000 ? "Mobile" : "Desktop"}>
							{info?.width < 1000 ? <Smartphone className="colour-text-accent-2" /> : info?.width >= 1000 ? <Monitor className="colour-text-primary" /> : "-"}
						</Tooltip>
					</td>
				</tr>
			);
		});

	// Filters
	const filterOnclick = (value) => {
		return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
	}

	useEffect(() => {
		let newList = analytics
		if (currentFilter === "pdfDownload") newList = analytics && analytics.filter(p => p.pdfDownloaded)
		if (currentFilter === "modelDownload") newList = analytics && analytics.filter(p => p.modelDownloaded)
		if (currentFilter === "emailViews") newList = analytics && analytics.filter(p => p.emailsRead)
		if (currentFilter === "portalViews") newList = analytics && analytics.filter(p => p.documentViewed)

		setFilteredList(newList)
	}, [currentFilter, analytics])


	function getImage(note) {
		if (allTickers && allTickers.length > 0 && note.ticker && note.ticker.includes(".")) {
			const ticker = allTickers.find(t => t.ric && t.ric === note.ticker)
			return ticker ? `/api/public/images/ticker/${ticker.id}.png` : null
		} else if (allGroups && allGroups.length > 0 && note.ticker && !note.ticker.includes(".")) {
			const group = allGroups.find(g => g.groupName === note.ticker)
			return group ? `/api/public/images/group/${group.id}.png` : null
		} else return null
	}

	if (!user) {
		return (
			<Page noBanner showScrollbar>
				<PageContentBodyGrid showScrollbar>
					<PageGridItem colSpan="12">
						<LoadingIcon centered />
					</PageGridItem>
				</PageContentBodyGrid>
			</Page>
		)
	}

	return (
		<Page noBanner showScrollbar>
			{/* Modal */}
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="l">
						{user ?
							<FlexWrapper gap="xs" direction="column" align="flex-start">
								<p className="font-weight-semi-bold">{user.firstName + " " + user.lastName}</p>
								<FlexWrapper direction="row" gap="s">
									<Tooltip label={user.email}>
										<Mail className='colour-text-neutral-dark-40 informative-icon' size={16} />
									</Tooltip>
									<Tooltip label={user.organisationName || "Unknown company"}>
										<Briefcase className='colour-text-neutral-dark-40 informative-icon' style={{ marginTop: -1 }} size={16} />
									</Tooltip>
								</FlexWrapper>
							</FlexWrapper> : loading ? <LoadingIcon size={32} /> : ""}
						<Divider vertical height={40} />
						<AnalyticsMetricFilter
							onClick={() => null}
							active={currentFilter === "avgSessionTime"}
							inactive={currentFilter !== "" && currentFilter !== "avgSessionTime"}
							label="Avg Session Time"
							status="read"
							icon={<Clock color='#0E8EFD' />}
							count={metrics?.averageSessionTime && !isNaN(metrics.averageSessionTime) ? minutesIntoReadableFormatWithSeconds(metrics.averageSessionTime) : "-"}
							style={{ cursor: "default" }}
						/>
						<AnalyticsMetricFilter
							onClick={() => null}
							active={currentFilter === "watching"}
							inactive={currentFilter !== "" && currentFilter !== "watching"}
							label="Watchlist"
							status="read"
							icon={<Users color='#00805E' />}
							count={metrics?.watchlist}
							iconColour="colour-text-positive-dark"
							style={{ cursor: "default" }}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("emailViews")}
							active={currentFilter === "emailViews"}
							inactive={currentFilter !== "" && currentFilter !== "emailViews"}
							label="Email Views"
							status="read"
							icon={<Mail />}
							count={metrics?.emailViews}
						/>
						<Tooltip label='Total views'>
							<AnalyticsMetricFilter
								onClick={() => filterOnclick("portalViews")}
								active={currentFilter === "portalViews"}
								inactive={currentFilter !== "" && currentFilter !== "portalViews"}
								label="Portal Views"
								icon={<Eye />}
								count={metrics?.portalViews}
							/>
						</Tooltip>
						<Tooltip label='Total PDF Downloads'>
							<AnalyticsMetricFilter
								onClick={() => filterOnclick("pdfDownload")}
								active={currentFilter === "pdfDownload"}
								inactive={currentFilter !== "" && currentFilter !== "pdfDownload"}
								label="PDF Downloads"
								icon={<PdfIcon noHover />}
								count={metrics?.pdfDownload}
							/>
						</Tooltip>
						<Tooltip label='Total Model Downloads'>
							<AnalyticsMetricFilter
								onClick={() => filterOnclick("modelDownload")}
								active={currentFilter === "modelDownload"}
								inactive={currentFilter !== "" && currentFilter !== "modelDownload"}
								label="Model Downloads"
								icon={<XlsIcon noHover />}
								count={metrics?.modelDownload}
							/>
						</Tooltip>
					</FlexWrapper>
				</PageGridItem>
				<PageGridDivider colSpan="12" />
				<PageGridItem>
					<SearchObjectFilter
						size="small"
						width="l"
						placeholder={`Search`}
						isClearable
						dataSet={filteredList}
						setFilteredData={setFilteredData}
						path={["ticker", "title"]}
						subProperty={"note"}
						hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
					/>
				</PageGridItem>
				<PageGridItem colSpan="12" style={{ paddingTop: "0.5rem" }}>
					<Table
						minWidth={1000}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(dataDisplaying)}
						noResults={{ message: "No research found" }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default AnalyticsUserPage;