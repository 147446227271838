


import React from 'react';
import DropDown from '../../DropDown';

const SchemeSelector = ({template, state, setState, onUpdateTickerGroup}) => {
	return (
		<div>
			<label className="label-input">{template.ticker.selectionName}</label>
			<DropDown clickable fullWidth value={state.selectedTickerGroup && state.selectedTickerGroup.offerName}>
				{state.selectionGroups
					.filter((option) => template.ticker?.schemeNumbers?.includes(option.offerNumber))
					.map((option, index) => (
						<li key={index} onClick={() => setState({ selectedTickerGroup: option }, onUpdateTickerGroup)}>
							{option.offerName}
						</li>
					))}
			</DropDown>
		</div>
	);
}

export default SchemeSelector;