import React, { useEffect, useState } from "react";
import { ChevronUp, ChevronDown, Users } from 'react-feather';
import { useRemote, useStorage } from '../../../Utils/Utils'
import DropDown from '../../../components/DropDown';
import PageGridItem from "../../Shared/Page/PageGridItem";

const ApprovalToPublishToPortalActions = (props) => {
	const { templateId, action, setSelectedPublishActions } = props
	const storage = useStorage()
	const remote = useRemote(

	)
	const [approversNumber, setApproversNumber] = useState(action?.paramValues?.approversNumber || 1)
	const [role, setRole] = useState(action?.paramValues?.role || null)
	const [roles, setRoles] = useState([])

	useEffect(() => {
		storage.getOrFetch("/roles/list-summary").then(setRoles)
	}, [])


	function changeNumberofApprovers(e) {
		let value = e.target.value
		if (value > 5) value = 5
		setApproversNumber(value)
	}

	// TODO: wire when params are given to the approve to publish actions (current actions id 4 and 5)
	function update(paramIndex) {
		const paramName = action?.params[paramIndex].name
		const value = paramName === role? role : approversNumber
		remote.put(`/crm/templates/${templateId}/publish-steps/${action.stepId}/${paramName}`, { "value": value }).then(setSelectedPublishActions);
	}


	return (
		<PageGridItem container col="1 / span 6" gap="l" style={{ display: 'grid', gridTemplateColumns: '1.5fr 2fr', padding: 0 }}>
			<div className='publish-action-expansion-approvers' style={{ display: 'flex' }}>
				<Users size={16} color='#0E8EFD' />
				<div>Aprprovers</div>
				<div className='number-of-approvers'>
					<input value={approversNumber} onChange={changeNumberofApprovers} />
					<div className='input-icons'>
						<ChevronUp size={16} color='#0E8EFD' onClick={() => approversNumber >= 5 ? null : setApproversNumber(approversNumber + 1)} />
						<ChevronDown size={16} color='#0E8EFD' style={{ opacity: approversNumber <= 1 ? 0.5 : 1 }} onClick={() => approversNumber <= 1 ? null : setApproversNumber(approversNumber - 1)} />
					</div>
				</div>
			</div>
			<div className='publish-action-expansion-role'>
				<div>Role Required</div>
				<DropDown fixed clickable fullWidth value={role?.role}>
					{roles.map((option, index) => (
						<li key={index + '-role'} onClick={() => setRole(option)}>
							{option.role}
						</li>
					))}
				</DropDown>
			</div>
		</PageGridItem>
	)
}

export default ApprovalToPublishToPortalActions