import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Edit2, Send, Trash2 } from 'react-feather';
import TableActionIcon from '../../components/Table/TableActionIcon';
import StatusToken from '../../components/StatusTokens';
import FlexWrapper from '../FlexWrapper';
import Divider from '../../components/Divider';
import DraftNotePreviewModal from '../../components/Modals/DraftNotePreviewModal';
import ConfirmActionModal from '../../components/Modals/ConfirmActionModal';
import { showConfirmActionModal, useRemote, useStorage } from '../../Utils/Utils';
import { useHistory } from 'react-router-dom';



const DraftsTableRow = ({ data, idx, expandedRows, refreshList }) => {
	const [document, setDocument] = useState({});
	const [showModal, setShowModal] = useState(false);
	// const [imageKey, setImageKey] = useState(Date.now());
	// const [showPublishModal, setShowPublishModal] = useState(false);
	// const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [confirmActionModal, setConfirmActionModal] = useState(null);

	const [compliance, setCompliance] = useState({});
	const [peer, setPeer] = useState({});
	const [me, setMe] = useState({});
	const perms = me?.perms || [];

	const storage = useStorage();
	const remote = useRemote();
	const history = useHistory();

	useEffect(() => {
		remote.get(`/client/review/compliance/${data?.ticker}/${data?.type}`).then(setCompliance);
		remote.get(`/client/review/peer/${data?.ticker}/${data?.type}`).then(setPeer);
		setDocument(data)
	}, [data])


	const actions = [
		{ label: "Edit", variant: null, disabled: !perms.includes("EDIT"), action: () => history.push(`/editor?template=${data?.type}${data.assetType === 'scheme' ? '&scheme=' + data?.ticker : data.assetGroup ? '&offer=' + data.assetGroup + '&fund=' + data?.ticker : '&asset=' + data?.ticker}`) },
		{ label: "Publish & Send", variant: "positive", disabled: !perms.includes("WORKSPACE_PUBLISH"), action: () => { showConfirmActionModal("publishDraft", setConfirmActionModal, data?.type, data?.ticker, refreshList); setShowModal(false) } },
		{ label: "Remove From List", variant: "danger", action: () => { showConfirmActionModal("removeDraft", setConfirmActionModal, data?.type, data?.ticker, refreshList); setShowModal(false) } }
	]

	useEffect(() => {
		storage.getOrFetch(`/crm/me`).then(setMe)
	}, [])

	return (
		<>
			{showModal && <DraftNotePreviewModal data={data} handleClose={() => setShowModal(false)} actions={actions} />}
			{confirmActionModal && <ConfirmActionModal {...confirmActionModal} />}
			<tr key={idx} className={expandedRows && expandedRows.includes(document.docId) ? "library-row-selected" : ""}>
				<td>
					{document && document.date && <p style={{ display: "flex", alignItems: "center" }}>{format(parseISO(document.date), "do MMM yyyy")}<span style={{ display: "inline-block", width: "3px", height: "3px", borderRadius: "5px", backgroundColor: "black", margin: "0px 5px" }} />{format(parseISO(document.date), "hh:mm aaa")}</p>}
				</td>
				<td>
					<div className="tsi-details">
						{/* <img src={preview} /> */}
						<div className="tsi-label">
							{document.ticker}
						</div>
					</div>
				</td>
				<td className="research-title">
					<p>{document && document.template ? document.template : document && document.type ? document.type : ""}</p>
				</td>
				<td>
					<p>{document.author}</p>
				</td>
				<td className="pl-m">
					{compliance && compliance.requested && <StatusToken type={"warning"} label={"Requested"} />}
					{compliance && compliance.approved && <StatusToken type={"primary"} label={"Approved"} />}
					{compliance && !compliance.requested && !compliance.approved && <StatusToken type={"draft"} label={"-"} />}
				</td>
				<td className="pl-m">
					{peer && peer.requested && <StatusToken type={"warning"} label={"Requested"} />}
					{peer && peer.approved && <StatusToken type={"primary"} label={"Approved"} />}
					{peer && !peer.requested && !peer.approved && <StatusToken type={"draft"} label={"-"} />}
				</td>
				{/* Row Actions */}
				<td>
					<FlexWrapper gap="l">
						{/* <TableActionIcon
							colour="primary"
							noMargin
							icon={<Eye />}
							toolTip="Preview Note"
							onClick={() => setShowModal(true)}
						// disabled={actions[0].disabled}
						/> */}
						<TableActionIcon
							toolTipDisabled={actions[0].disabled}
							disabled={actions[0].disabled}
							colour="primary"
							noMargin
							icon={<Edit2 />}
							toolTip={actions[0].label}
							onClick={actions[0].action}
						/>
						<Divider className="mr-m" vertical height={32} />
						<TableActionIcon
							toolTipDisabled={actions[1].disabled}
							disabled={actions[1].disabled}
							colour="positive"
							noMargin
							icon={<Send />}
							toolTip={actions[1].label}
							onClick={actions[1].action}
						/>
						<TableActionIcon
							colour="danger"
							noMargin
							icon={<Trash2 />}
							toolTip={actions[2].label}
							onClick={actions[2].action}
						/>
					</FlexWrapper>
				</td>
			</tr>
		</>
	);
}

export default DraftsTableRow;