import React, { useState } from "react";
import { useRemote } from '../../../Utils/Utils';
import DetailField from '../../Shared/DetailSection/DetailField'
import Button from "../../../components/Buttons/Button";

const ParamField = ({ param, template, action, setSelectedPublishActions }) => {
	const value = (action.paramValues && action.paramValues[param.name]) || "";
	const remote = useRemote();
	const [editor, setEditor] = useState(value);

	const update = () => {
		remote.put(`/crm/templates/${template}/publish-steps/${action.stepId}/${param.name}`, { "value": editor }).then(setSelectedPublishActions);
	}

	return (
		<div style={{ width: "100%" }}>
			<DetailField variant="light" invalid={editor === "" ? "invalid" : undefined} editMode={true} autoFocus placeholder={param.description} label={param.name} value={editor} onChange={(e) => setEditor(e.target.value)} />
			<Button className="mt-s" disabled={editor === value} onClick={update}>Save</Button>
		</div>
	)
};

export default ParamField;