import React, { useEffect, useState } from 'react';
import { ExternalLink } from 'react-feather';
import Checkbox from '../../Checkbox';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';
import { ROUTE_PATHS, PAGES } from "../../../InternalApiApp"
import { useRemote } from '../../../Utils/Utils';
import { useHistory } from 'react-router-dom';

const ModelSidebarExtension = ({ slideIn, slideOut }) => {
	const [model, setModel] = useState({})
	const [loading, setLoading] = useState({})
	const [noModels, setNoModels] = useState({})
	const [activeClasses, setActiveClasses] = useState()

	const remote = useRemote()
	const history = useHistory()

	useEffect(() => {
		remote.get("/crm/groupings").then(groupings => {
			if (groupings && groupings.length) {
				const modelPortfolios = groupings.filter(g => g.groupName === 'Model Portfolio');
				if (modelPortfolios.length) {
					remote.get(`/crm/groupings/${modelPortfolios[0].id}`).then(modelGrouping => {
						setModel(modelGrouping)
						setLoading(false);
					})
				} else {
					setLoading(false);
					setNoModels(true);
				}
			}
		})
	}, [])

	return (
		<SidebarExtensionWrapper noPadding position="right" shadow slideIn={slideIn} slideOut={slideOut}>
			<SideBarSection header={<>Models<ExternalLink onClick={() => { history.push(ROUTE_PATHS[PAGES.ADMIN] + "/model-portfolio") }} /></>}>
				
				{model && model.groups && model.groups.map((val) => {
					return (
						<div style={{marginBottom: "1rem"}}>
							<Checkbox key={val.id} label={val.groupName} checked={false} onClick={() => null} />
						</div>
					)
				})}

			</SideBarSection>
		</SidebarExtensionWrapper>
	);
}

export default ModelSidebarExtension;