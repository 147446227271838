import { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import logo from './images/3SKYE-logo-black.png'
import LoadingIcon from './components/LoadingIcon';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const CheckAuthorisation = (props) => {

    const [loading, setLoading] = useState(true);
    const [failed, setFailed] = useState();

    const { setAuthorised } = props;

    const location = useLocation();

    // console.log("Check auth has use location of ", location)

    const checkAuth = () => {
        fetch("/api/crm/me", { credentials: 'same-origin' }).then(resp => {
            // console.log("Response from check auth", resp, resp.status);
            if (resp && resp.status && resp.status === 200) {
                // console.log("good");
                setAuthorised(true);
            } else {
                console.log('response but bad')
                let search = location.search.length > 1 ? location.search.substring(1) : '';
                window.location.href = `/api/login?path=${location.pathname}&query=${search}`;
            }

        }, (error) => {
            console.log("Promise rejected with ", error)
        })
    }

    useEffect(() => {
        // console.log("Check authorisation checking")
        checkAuth();
    }, [])

    return (
        <div
            style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '20px', backgroundColor: '#fff' }}
        >
            <img src={logo} alt="3skye logo" style={{ maxHeight: "150px", maxWidth: "300px" }} />
            <h3>
                Checking your Authorisation.
            </h3>
            <LoadingIcon />
        </div>
    )
}

export default CheckAuthorisation;