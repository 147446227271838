import React, { useEffect, useState } from "react";
import PdfIcon from "../../../images/icons/PdfIcon";
import XlsIcon from "../../../images/icons/XlsIcon";


const ActionDiscloseFiles = (props) => {
    // TODO: call and endpoint
    const fakeFiles = [{ name: 'Fund_Update.pdf', type: 'pdf' }, { name: 'fund-returns-data.xls', type: 'xls' }, { name: 'general-fund-data.xls', type: 'xls' }, { name: 'full-portfolio-holdings-data.xls', type: 'xls' }]
    const [filesList, setFilesList] = useState([])

    useEffect(() => {
        // TODO: add real endpoint to get the dicloser files
        setFilesList(fakeFiles)
    }, [])


    return (
        <div className='publish-action-disclose-file-list'>
            {filesList.map(file => (
                <div className='publish-action-disclose-file-name'>
                    {file.type === 'pdf' ? <PdfIcon noHover /> : <XlsIcon noHover />}
                    {file.name}
                </div>

            ))}
        </div>
    )
}

export default ActionDiscloseFiles