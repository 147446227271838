import React, { useEffect, useRef, useState } from 'react';
import { Trash, TrendingUp } from 'react-feather';
import Table from '../../../components/Table/Table';
import PageGridItem from '../Page/PageGridItem';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import DetailSectionHeader from '../DetailSection/DetailSectionHeader';
import SearchField from '../../../components/Input/SearchField';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import FlexWrapper from '../../FlexWrapper';
import { toast, toastDanger, toastSuccess } from '../../../components/popups/Toast';

const MiniTickerList = ({ editMode, data, header, list, setData, createMode }) => {
	const colWidths = [90 + "%", 10 + "%"];
	const { width } = useWindowDimensions();
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [allTickers, setAllTickers] = useState([])

	const searchSelect = useRef();
	const remote = useRemote()

	useEffect(() => { getAddTickerOptions() }, [])

	const getAddTickerOptions = () => {
		remote.get("/crm/tickers")
			.then(response => {
				const mappedTickers = response.map((ticker) => {
					return { value: ticker.id, label: ticker.ric, tickerDTO: ticker }
				})
				setAllTickers(mappedTickers)
			});
	}

	const addTicker = (ticker) => {
		remote.put(`/crm/lists/${list.id}/tickers/${ticker}`)
			.then((response) => {
				toastSuccess('Ticker added')
				setData(response)
			})
			.catch(() => toastDanger('Ticker could not be added'))
	}

	const removeTicker = (ticker) => {
		remote.delete(`/crm/lists/${list.id}/tickers/${ticker.id}`)
			.then((response) => {
				toast("Ticker removed");
				setData(response)
			})
			.catch(() => toastDanger('Ticker could not be removed'))
	}

	const dataMap = (dataToMap) => dataToMap && dataToMap.map((ticker, idx) => {
		return (
			<tr key={idx} className={editMode ? "" : "cursor-pointer"}>
				<td>
					<div className="truncate">
						<TickerSectorItem size="m" label={ticker.ric} extensionLabel={ticker.name} image={ticker.logo} country={ticker.country} filename={ticker.ric} />
					</div>
				</td>
				<td className="table-action-icons transition-fade-in-slow">
					<TableActionIcon
						icon={<Trash />}
						toolTip="Remove ticker"
						onClick={(e) => {
							e.stopPropagation();
							removeTicker(ticker);
						}}
					/>
				</td>
			</tr>
		);
	})

	const tickersLength = data && data.length;
	const noData = !data || tickersLength <= 0;
	const noSearchResults = false;
	const xl = width > 2250;
	const l = width > 1900;

	return (
		<>
			<PageGridItem col="1 / span 6" justifyContent="between" gap="m" wrap>
				<DetailSectionHeader icon={<TrendingUp />} header={`${header ? header : "Tickers"} (${noData ? "0" : tickersLength})`} />
				<FlexWrapper wrap gap="s">
					<SearchTermFilter
						size="small"
						width={xl ? "l" : l ? "m" : "s"}
						placeholder="Search tickers"
						isClearable
						dataSet={data}
						setFilteredData={setFilteredData}
						path={["ric", "name", "country", "currencyCode", "gicGroup", "gicIndustry", "gicSector", "gicSubIndustry"]}
						hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
					/>
					{!createMode && (
						<>
							<SearchField
								onChange={(ticker) => {
									addTicker(ticker.value);
								}}
								options={allTickers}
								placeholder={`Add ticker`}
								size="small"
								width={noData && xl ? "l" : l ? "m" : "s"}
								innerRef={searchSelect}
								value=""
							/>
							{/* <SearchField
								onChange={(sector) => {
									alert("This is supposed to add the sector: " + sector.label);
								}}
								options={allSectors}
								placeholder={`Add sector`}
								size="small"
								width={noData && xl ? "l" : l ? "m" : "s"}
							/>
							<SearchField
								onChange={(exchange) => {
									alert("This is supposed to add the exchange: " + exchange.label);
								}}
								options={allExchanges}
								placeholder={`Add exchange`}
								size="small"
								width={noData && xl ? "l" : l ? "m" : "s"}
							/> */}
						</>
					)}
				</FlexWrapper>
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				{createMode ? (
					<SuggestionMessage
						message={
							<>
								Create list before adding tickers.
							</>
						}
					/>
				) : noData ? (
					<SuggestionMessage
						message={
							<>
								No tickers added yet. <span className="text-link" onClick={() => searchSelect && searchSelect.current.focus()}>Try adding one now.</span>
							</>
						}
					/>
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : (
					<Table colWidths={colWidths} dataMap={dataMap(hasSearchTerm ? filteredData : data)} />
				)}
			</PageGridItem>
		</>
	);
}

export default MiniTickerList;