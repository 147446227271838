import React, { useEffect, useState } from 'react';
import { Eye } from 'react-feather';
import { useRemote } from '../../../Utils/Utils';
import { useLocation } from "react-router";
import {
    generateLighterColors, extractColours, extractTypographies, ColourSample, getSubBrandName,
    allCompaniesStyles, extractBrandTypography, getCategoryChartData, getCategoryChartConfig
} from './BrandsStyles'
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Divider from '../../../components/Divider';
import Button from '../../../components/Buttons/Button';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import LoadingIcon from '../../Shared/LoadingIcon';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField'
import './StyleGuide.scss'
import SideBarExtensionHeader from '../../../components/SideBar/SideBarExtension/SideBarExtensionHeader';

const SubBrandPage = (props) => {
    const remote = useRemote();
    const location = useLocation();
    const pathname = location.pathname
    const initialTemplate = pathname.split("/admin/brand-settings/sub-brands/")[1]
    console.log('initialTemplate', initialTemplate)

    const [viewMaster, setViewMaster] = useState(false)
    const [template, setTemplate] = useState(initialTemplate)
    const [brandColours, setBrandColours] = useState([])
    const [allColoursList, setAllColoursList] = useState({})
    const [allTypographiesList, setAllTypographiesList] = useState({})
    const [pieChartImage, setPieChartImage] = useState(null)
    const [categoryChartImage, setCategoryChartImage] = useState(null)
    const [logoInfo, setLogoInfo] = useState({})
    const [tableStyles, setTableStyles] = useState({})
    const [fontConfig, setFontConfig] = useState({})
    const [loading, setLoading] = useState(true)
    const [pieDataConfig, setPieDataConfig] = useState(null)

    const masterColourArray = ['#008fd3', '#154a9a', '#f06ecd', '#d81521', '#60c9d3', '#64a85a', '#7961a2']
    const materLogoInfo = allCompaniesStyles['colonial'].logoInfo

    useEffect(() => {
        setLoading(true)
        const path = `/data/templates/${getSubBrandName(template, 'template')}`

        remote.get(path).then(fetchedNote => {
            const styles = fetchedNote.styles
            //colours
            const baseColor = template === 'colonial' ? '#224C95' : '#0d8efd'
            const lightestColour = template === 'colonial' ? '#e9edf4' : '#ebf4ff'
            // banner
            const companyHeader = template === 'kernel' ? 'page-header' : template === 'colonial' || template === 'hejaz' || template === 'my-north' ? 'title-page-header' : template === 'simplicity' ? 'title-page-header-orange' : 'company-header'
            const subPageHeader = template === 'kernel' ? 'page-sub-header' : template === 'simplicity' ? 'title-page-header' : (template === 'colonial' || template === 'hejaz' || template === 'my-north') ? 'title-page-sub-header' : 'research-header'
            // chart
            const miniDataValue = template === 'threeskye' ? 'mini-data-value' : template === 'simplicity' ? 'body-text' : 'body-text-small'
            const chartText = template === 'threeskye' ? 'mini-data-value' : 'body-text-small'
            const bodyHeader = template === 'kernel' ? 'body-h2' : 'body-header'
            const chartLine1 = template === 'hejaz' ? '#D4AE63' : '#004271'
            const chartLine2 = template === 'hejaz' ? '#001E41' : '#72C1C6'
            // table
            const tableCellValue = 'table-cell'
            const tableHeader1 = template === 'my-north' ? 'table-header-row' : 'table-header-one'
            const tableHeader2 = template === 'simplicity' ? 'top-10-table-cell-value' : 'table-header-two'
            const tableHeader3 = template === 'simplicity' ? 'table-cell-header' : template === 'colonial' ? 'table-header-row' : 'table-header-three'

            const chartStyles = {
                width: 200,
                height: 132,
                styles: { p: chartText, h1: 'body-header' },
                backgroundColour: '#ffffff',
                gridColour: "#ffffff",
                axisColour: "#2B2E34",
                series: [{ colour: chartLine1 }, { colour: chartLine2 }]
            }

            const masterChartStyles = {
                width: 200,
                height: 132,
                styles: { p: chartText, h1: 'body-header' },
                backgroundColour: '#ffffff',
                gridColour: "#ffffff",
                axisColour: "#2B2E34",
                series: [{ colour: '#d81521' }, { colour: '#008fd2' }]
            }

            const stylesArray = [
                {
                    category: 'Banner', values: [
                        { backgroundColor: '#FFFFFF', label: 'Banner Background', keyName: 'bannerBackground' },
                        { ...styles[companyHeader], label: 'Banner Header', keyName: 'bannerHeader' },
                        { ...styles[subPageHeader], label: 'Banner Sub-header', keyName: 'bannerSubheader' },
                    ]
                },
                {
                    category: 'Tables',
                    values: [
                        { ...styles[tableHeader1], label: 'Table Header 1', keyName: 'tableHead1' },
                        { ...styles[tableHeader2], color: template === 'my-north' ? '#fff' : styles[tableHeader2].color, backgroundColor: template === 'my-north' ? '#1c525d' : styles[tableHeader2].backgroundColor, label: 'Table Header 2', keyName: 'tableHead2' },
                        { ...styles[tableHeader2], label: 'Table Header 2', keyName: 'tableHead2' },
                        { ...styles[tableHeader3], label: 'Table Header 3', keyName: 'tableHead3' },
                        { ...styles['table-highlight-one'], backgroundColor: template === 'my-north' ? '#d3f5fc' : template === 'hejaz' ? '#f9f0e1' : styles['table-highlight-one'].backgroundColor, label: 'Row Highlight 1', keyName: 'rowHighlight1' },
                        { ...styles[tableHeader2], label: 'Table Header 2', keyName: 'tableHead2' },
                        { ...styles[tableHeader3], label: 'Table Header 3', keyName: 'tableHead3' },
                        { ...styles[tableCellValue], backgroundColor: '#fff', label: 'Table Body Cell', keyName: 'tableCell' },
                    ]
                },
                {
                    category: 'Charts',
                    values: [
                        { color: chartStyles.axisColour, label: 'Chart Axis', keyName: 'axis' },
                        { color: chartStyles.backgroundColour, label: 'Chart Background', keyName: 'chartBackground' },
                        { color: chartStyles.gridColour, label: 'Chart Grid', keyName: 'grid' },
                        { color: chartStyles.series[0].colour, label: 'Chart Line/Bar 1', keyName: 'line1' },
                        { color: template === 'my-north' ? '#546f78' : chartStyles.series[1].colour, label: 'Chart Line/Bar 2', keyName: 'line2' },
                        { ...styles[bodyHeader], label: 'Chart Title', keyName: 'chartTitle' },
                        { ...styles[miniDataValue], label: 'Chart Text', keyName: 'chartText' }
                    ]
                },
                {
                    category: 'Pie Charts',
                    values: template === 'colonial' || template === 'hejaz' || template === 'my-north' ?
                        [
                            { color: template === 'colonial' ? '#008fd3' : template === 'my-north' ? '#546f78' : '#D4AE63', label: 'Pie Chart Colour1', keyName: 'Colour1' },
                            { color: template === 'colonial' ? '#154a9a' : template === 'my-north' ? '#052845' : '#8e5c2d', label: 'Pie Chart Colour2', keyName: 'Colour2' },
                            { color: template === 'colonial' ? '#f06ecd' : template === 'my-north' ? '#1c525d' : '#eccead', label: 'Pie Chart Colour3', keyName: 'Colour3' },
                            { color: template === 'colonial' ? '#d81521' : template === 'my-north' ? '#c7c9e8' : '#d27d28', label: 'Pie Chart Colour4', keyName: 'Colour4' },
                            { color: template === 'colonial' ? '#60c9d3' : template === 'my-north' ? '#798194' : '#4f6ea3', label: 'Pie Chart Colour5', keyName: 'Colour5' },
                            { color: template === 'colonial' ? '#64a85a' : template === 'my-north' ? '#4bb9e1' : '#94949a', label: 'Pie Chart Colour6', keyName: 'Colour6' },
                            { color: template === 'colonial' ? '#7961a2' : template === 'my-north' ? '#c2e7ed' : '#001E41', label: 'Pie Chart Colour7', keyName: 'Colour7' },
                            { color: '#fff', label: 'Pie Chart background', keyName: 'pieChartBackground' }
                        ] :
                        [
                            { color: baseColor, label: 'Pie Chart Base', keyName: 'baseColour' },
                            { color: lightestColour, label: 'Pie Chart last', keyName: 'lightestColour' }, // #8ea4c9
                            { color: '#fff', label: 'Pie Chart background', keyName: 'pieChartBackground' }
                        ]
                }
            ]

            const coloursList = extractColours(stylesArray)
            const typographiesList = extractTypographies(stylesArray, template)
            const typography = template === 'colonial' ? extractBrandTypography(stylesArray, template) : extractBrandTypography(stylesArray, template)
            const colourArray = template === 'hejaz' ?
                ['#D4AE63', '#8e5c2d', '#d27d28', '#eccead', '#94949a', '#4f6ea3', '#001E41'] : template === 'my-north' ?
                    ['#546f78', '#052845', '#1c525d', '#c7c9e8', '#798194', '#4bb9e1', '#d3f5fc'] : generateLighterColors(stylesArray[3].values[0].color, stylesArray[3].values[1].color)

            const fontArray = Object.values(fetchedNote.fonts)
            const fonts = fontArray.map(obj => { return { family: obj.name, url: obj.importLink } })
            const fontsConfiguration = { custom: { families: fonts.map(f => f.family), urls: fonts.map(f => f.url) } }

            const tableStyleObject = viewMaster ?
                {
                    font: { fontFamily: typographiesList['tableCell'].fontFamily, fontSize: typographiesList['tableCell'].size },
                    row1: { backgroundColor: '#164a9a', color: '#fff' },
                    row2: { backgroundColor: '#8EA4C9', color: '#0D0D0D' },
                    heightlight1: { backgroundColor: '#E9EDF4', color: '#0D0D0D' },
                    default: { backgroundColor: '#fff', color: '#0D0D0D' },
                } :
                {
                    font: { fontFamily: typographiesList['tableCell'].fontFamily, fontSize: typographiesList['tableCell'].size },
                    row1: { backgroundColor: coloursList['tableHead1Background'], color: coloursList['tableHead1Text'] },
                    row2: { backgroundColor: coloursList['tableHead2Background'], color: coloursList['tableHead2Text'], borderBottom: coloursList['tableHead2Background'] === coloursList['tableHead3Background'] ? 'solid 1px #ffffff' : null },
                    heightlight1: { backgroundColor: coloursList['rowHighlight1Background'], color: coloursList['rowHighlight1Text'] },
                    default: { backgroundColor: coloursList['tableCellBackground'], color: coloursList['tableCellText'] },
                }
            const string = template === 'my-north' ? 'north' : template
            setBrandColours(stylesArray)
            setAllColoursList(coloursList)
            setAllTypographiesList(typographiesList)
            setFontConfig(fontsConfiguration)
            setLogoInfo(allCompaniesStyles[string].logoInfo)
            setTableStyles(tableStyleObject)

            const categoryChartConfig = getCategoryChartConfig(chartStyles.styles, coloursList)
            const categoryChartData = getCategoryChartData(categoryChartConfig)
            const masterCategoryChartConfig = getCategoryChartConfig(masterChartStyles.styles, null)
            const masterCategoryChartData = getCategoryChartData(masterCategoryChartConfig)

            const pieDataConfiguration = {
                width: 360,
                height: 200,
                styles: { legend: 'page-sub-header' },
                backgroundColour: '#ffffff',
                legendColour: '#22495f',
                colours: viewMaster ? masterColourArray : colourArray,
                title: { value: '' },
                data: { value: '${WhereGraph}' }
            }

            const pieChartData = {
                "data": {
                    "data": {
                        "TICKERGROUP": "Balanced Fund",
                        "TICKER": "Balanced",
                        "HISTORICPRICESREFERENCEDATE": "2023-10-01",
                        "HISTORICVALUATIONS": "[15123.0,14123.0,13000.0,13111.0,12123.0,12111.0,12007.0,12009.0,11888.0,11777.0,11666.0,11555.0,11565.0,11500.0,11400.0,11300.0,11450.0,11400.0,11350.0,11250.0,11200.0,11280.0,11100.0,11000.0,11008.0,10998.0,10990.0,10890.0,10790.0,11001.0,10990.0,10800.0,10700.0,10550.0,10720.0,10500.0,10400.0,10300.0,10305.0,10350.0,10300.0,10250.0,10000.0]",
                        "WHEREGRAPH": "{\"Cash\":15.1,\"International Fixed Interest\":18.6,\"Australian Fixed Interest\":34.3,\"Other\":3.1,\"Property\":1.9,\"International Equities\":14.4,\"Australian Equities\":12.7}",
                        "WHATGRAPH": "{\"Growth\":60.0,\"Income\":40.0}",
                        "TICKERLIST": "Balanced Fund"
                    }
                },
                "className": "PieChart",
                "dataSuffix": null,
                "configuration": JSON.stringify(pieDataConfiguration)
            };


            setPieDataConfig(pieDataConfiguration)

            if (colourArray && colourArray.length > 0 && pieChartData && chartStyles.styles && chartStyles.styles.h1) {
                remote.post("/ssrender/Kernel Fund Fact Sheet", pieChartData).then(resp => {
                    setPieChartImage(resp.data);
                    setLoading(false)
                }).catch(() => setPieChartImage(null))

                const data = viewMaster ? masterCategoryChartData : categoryChartData

                remote.post("/ssrender/Company Note", data).then(resp => {
                    setCategoryChartImage(resp.data);
                }).catch(() => setCategoryChartImage(null))
            }
        })

    }, [location.pathname, template, viewMaster])


    return (
        <Page noBanner noOverflownY style={{ paddingLeft: '1rem' }}>
            <PageContentBodyGrid rowGap='m' gridColCount='4' paddingTop='0rem' subGrid gridTemplateColumns='270px 21cm' noOverflownY>
                <PageGridItem container className='second-sidebar-left'>
                    <div className='second-sidebar-left-container'>
                        <SelectDetailField editMode={true} placeholder='Select' label="Brand Name" value={{ value: template, label: getSubBrandName(template) }} options={[{ value: template, label: getSubBrandName(template) }]} onChange={(option) => setTemplate(option)} />
                        <SelectDetailField editMode={true} placeholder='Select' label="Template" value={{ value: 'CFS Fact Sheet', label: 'CFS Fact Sheet' }} options={[{ value: 'CFS Fact Sheet', label: 'CFS Fact Sheet' }]} onChange={(option) => setTemplate(option)} />
                        <SelectDetailField editMode={true} placeholder='Select' label="Template" value={{ value: template, label: getSubBrandName(template, 'email') }} options={[{ value: template, label: getSubBrandName(template, 'email') }]} onChange={(option) => setTemplate(option)} />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Logo</div>
                        <label className='colour-text-label-grey'>{'Recommended size ' + logoInfo.recommendedSize}</label>
                        <div style={logoInfo.imageContainerStyle}>
                            <img style={logoInfo.imageStyle} src={logoInfo.src} alt="logo" id="image-template-logo" />
                        </div>
                        <Button disabled style={{ width: 'fit-content' }}>Upload</Button>
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Colours</div>
                        {brandColours && brandColours.length > 0 && brandColours.map((obj, index) => {
                            return (
                                <div className='sample-group' key={obj.category + "-" + index}>
                                    <label className='colour-text-label-grey'>{obj.category}</label>
                                    {obj.values.map((val, idx) => {
                                        return (
                                            obj.category === 'Tables' ?
                                                <>
                                                    {val.color && <ColourSample colour={val.color} label={val.label + ' Text'} key={`key-${idx}-${val.color}`} />}
                                                    {val.backgroundColor && <ColourSample colour={val.backgroundColor} label={val.label + ' Background'} key={`key-${idx}-${val.backgroundColor}`} />}
                                                    {val.borderBottomColor && !val.color && <ColourSample colour={val.borderBottomColor} label={val.label} key={`key-${idx}-${val.borderBottomColor}`} />}
                                                </>
                                                : <ColourSample colour={val.color} label={val.label} key={`key-${idx}-${val.color}`} />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>

                    <div className='second-sidebar-left-container no-border'>
                        <Button disabled>Save Changes</Button>
                        <Button disabled variant='secondary'>Save as Default</Button>
                    </div>
                </PageGridItem>
                <PageGridItem container className='rest-of-the-page-width-second-sidebar-left'>
                    <PageContentBanner divider gap='m' dividerThinkness='m' style={{ height: 80 }}>
                        <SideBarExtensionHeader header='Preview' icon={<Eye color='#0E8EFD' />} style={{ marginBottom: 0, paddingRight: 0 }} noTabs />
                        <Divider vertical height='24px' marginTop='0.25rem' />
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', height: 35 }}>
                            {viewMaster ? <i className='material-icons toggle-on' onClick={() => setViewMaster(false)}>toggle_on</i> : <i className='material-icons toggle-off' onClick={() => setViewMaster(true)}>toggle_off</i>}
                            <div style={{ paddingBottom: 3 }}>View Master</div>
                        </div>
                        <Divider vertical height='24px' marginTop='0.25rem' />
                        <Button disabled >Save changes</Button>
                        <Button disabled variant='secondary' >Save as Default</Button>
                    </PageContentBanner>
                    {loading ? <LoadingIcon centered /> :
                        <PageContentBodyGrid paddingTop='0px'>
                            <WebfontLoader onStatus={() => { }} config={fontConfig}>
                                <PageGridItem colSpan='12' >
                                    <div className='sample-content-section'>
                                        <label className='colour-text-label-grey'>Logo + Banner</label>
                                        {viewMaster ?
                                            <div className='sample-banner-with-logo' style={materLogoInfo.logoInBanner}>
                                                <img style={materLogoInfo.imageStyle} src={materLogoInfo.src} alt="logo" id="image-template-logo" />
                                                {allTypographiesList.bannerHeader && allColoursList && <div className='sample-banner-text'>
                                                    <div style={{ color: '#0d0d0d', fontSize: '32.43pt', fontFamily: 'serif' }}>Fact Sheet</div>
                                                    <div style={{ color: '#0d0d0d', fontSize: '16.68pt', fontFamily: 'Gotham Book' }}>Lorem ipsum dolor sit amet</div>
                                                </div>}
                                            </div>
                                            : logoInfo.bannerSrc ?
                                                <div style={{ height: logoInfo.bannerHeight }}>
                                                    <img src={logoInfo.bannerSrc} className={`sample-banner-background ${template}`} />
                                                    <div className='sample-banner-with-logo' style={logoInfo.logoInBanner}>
                                                        {allTypographiesList.bannerHeader && allColoursList && <div className={`sample-banner-text ${template}`}>
                                                            <div style={{ color: allColoursList.bannerHeader, fontSize: template === 'simplicity' ? '40pt' : allTypographiesList.bannerHeader.size, fontFamily: allTypographiesList.bannerHeader.fontFamily, fontWeight: 'regular' }}>Fact Sheet</div>
                                                            <div style={{ color: allColoursList.bannerSubheader, fontSize: template === 'simplicity' ? '24pt' : allTypographiesList.bannerSubheader.size, fontFamily: allTypographiesList.bannerSubheader.fontFamily, fontWeight: allTypographiesList.bannerSubheader.weight, textAlign: 'left', paddingTop: '1rem' }}>Lorem ipsum dolor sit amet</div>
                                                        </div>}
                                                        <img style={logoInfo.imageStyle} src={logoInfo.src} alt="logo" id="image-template-logo" className={`image-template-logo ${template}`} />
                                                    </div>
                                                </div> :
                                                <div className='sample-banner-with-logo' style={logoInfo.logoInBanner}>
                                                    <img style={logoInfo.imageStyle} src={logoInfo.src} alt="logo" id="image-template-logo" />
                                                    {allTypographiesList.bannerHeader && allColoursList && <div className='sample-banner-text'>
                                                        <div style={{ color: allColoursList.bannerHeader, fontSize: template === 'simplicity' ? '40pt' : allTypographiesList.bannerHeader.size, fontFamily: allTypographiesList.bannerHeader.fontFamily, fontWeight: allTypographiesList.bannerHeader.weight }}>Banner Header</div>
                                                        <div style={{ color: allColoursList.bannerSubheader, fontSize: template === 'simplicity' ? '24pt' : allTypographiesList.bannerSubheader.size, fontFamily: allTypographiesList.bannerSubheader.fontFamily, fontWeight: allTypographiesList.bannerSubheader.weight }}>Lorem ipsum dolor sit amet</div>
                                                    </div>}
                                                </div>
                                        }
                                    </div>
                                    <div className='sample-content-section double-column' style={{ paddingBottom: '2.5rem' }}>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>Line Chart</label>
                                            {categoryChartImage && <img alt="category chart" style={{ width: 360, height: 210, marginTop: '-2rem' }} src={"data:image/png;base64," + categoryChartImage} />}
                                        </div>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>Pie Chart</label>
                                            {pieChartImage && <img alt="pie chart" style={{ width: pieDataConfig.width, height: pieDataConfig.height, marginBottom: '-0.5rem' }} src={"data:image/png;base64," + pieChartImage} />}
                                        </div>
                                    </div>
                                    <div className='sample-content-section' style={{ position: 'relative', marginTop: '-1.5rem', borderTop: 'solid 1.5px #E7EAF2', backgroundColor: '#fff' }}>
                                        <label className='colour-text-label-grey'>Table Col/Row</label>
                                        <div className='sample-tables'>
                                            <table className='sample-table' style={tableStyles.font}>
                                                <thead>
                                                    <tr style={tableStyles.row1}>
                                                        <th>Header 1</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={tableStyles.row2}>
                                                        <td>Header 2</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles.heightlight1}>
                                                        <td>Header 3</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles.default}>
                                                        <td>Header 4</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </PageGridItem>
                            </WebfontLoader>
                        </PageContentBodyGrid>
                    }
                </PageGridItem>

            </PageContentBodyGrid>
        </Page >
    )
}

export default SubBrandPage;