import React, { Component } from 'react';
import { AlertCircle } from 'react-feather';


export default class AbsoluteComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hasError: false
		}
	}
	
	componentDidCatch(error, info) {
		console.log(error)
		this.setState(state => ({ ...state, hasError: true }))
	}

	
	render() {
		//dying on spread operator inside Eikon, so changed to not use.
		const otherStyles = this.props.style || {};
		otherStyles.position = "absolute";
		otherStyles.left = this.props.x + 'pt';
		//otherStyles.zIndex = 1000;

		if (this.props.relativeBottom) {
			otherStyles.bottom = this.props.bottom;
		} else {
			otherStyles.top = this.props.y + 'pt';
		}
		const content = { ...this.props.content, 
			props: { ...this.props.content.props, 
				children: { ...this.props.content.props.children, 
					props: { ...this.props.content.props.children.props, 
						location: this.props.location, 
						elementsWithSupercript: this.props.elementsWithSupercript, 
						locatedFootnote: this.props.locatedFootnote, 
						unsetLocatedFootnote: this.props.unsetLocatedFootnote,
						pinSelected: this.props.pinSelected
					} 
				}
			}
		}
		return <div style={otherStyles} key={this.props.normalKey} >{this.state.hasError ? <Error/> : content}</div>
	}
}

const Error = () => <><AlertCircle color={"#E66780"}/>Critical error in component</>
