/*
 * All things storage.  Kind of like redux off steroids
 * copied from @threeskye/global - sessionStorage.js
 */
class SessionStorage {

    constructor(remote) {

        this.data = {};
        this.listeners = {};
        this.settingsListeners = {};
        this.activeCalls = {};
        this.remote = remote;

        this.get = this.get.bind(this);
        this.put = this.put.bind(this);
        this.getOrFetch = this.getOrFetch.bind(this);
		this.refresh = this.refresh.bind(this);
		this.logout = this.logout.bind(this);
		this.clear = this.clear.bind(this);
    }

    get(key) {
        return this.data[key] || null;
    }

    put(key, value) {
        this.data[key] = value;
        this.wasMutated(key, value);
    }

    clear() {
        this.data = {};
	}
	
	clear(regex) {
		for (let [key, value] of Object.entries(this.data)) {
			if (key.match(regex)) {
				delete this.data[key];
			}
		}
	}

	logout() {
		this.clear();
		this.remote.logout();
	}
	
    /*
     * Returns a promise of the given data
     */
    getOrFetch(apiCall, refresh) {
        //Is it already cached?
        const result = this.get(apiCall);
        if (!refresh && result !== null) {
            return Promise.resolve(result);
        } else {
            //Is there already a call in play?
            if (this.activeCalls.hasOwnProperty(apiCall)) {
                return this.activeCalls[apiCall];
            } else {
                const promise = this.remote.get(apiCall)
                    .then(x => {
                        this.put(apiCall, x);
                        delete this.activeCalls[apiCall];
                        return x;
                    })
                    .catch(e=>{
                        delete this.activeCalls[apiCall];
                    });
                this.activeCalls[apiCall] = promise;
                return promise;
            }
        }
    }

    refresh(apiCall) {
        return this.getOrFetch(apiCall, true).then(x=>{
            this.wasMutated(apiCall, x);
            return x;
        });
    }

	refreshRequired(apiCall) {
		var listenerList = this.listeners[apiCall];
		if (!listenerList) {
			//Just clear it
			delete this.data[apiCall];
			return;
		}
		return this.refresh(apiCall);
	}
	
    watch(key, listener) {
       var listenerList = this.listeners[key];
        if (!listenerList) {
            listenerList = [];
            this.listeners[key] = listenerList;
        }
        listenerList.push(listener);
    }

    unwatch(key, listener) {
        var listenerList = this.listeners[key];
        if (!listenerList) {
            return;
        }
        this.listeners[key] = listenerList.filter(x=>x!==listener);
    }

    wasMutated(key, value) {
        var listenerList = this.listeners[key];
        if (!listenerList)
            return;
        
         for (var i = listenerList.length-1; i>=0; i--) {
            listenerList[i](value);
        }
    }

    //Shortcut method for getting user settings
    getSetting(key) {
        //Always download latest settings (?????TODO store locally while waiting for download?????)
        return this.getOrFetch("/users/current-user/settings")
            .then(settings=>settings[key]);
    }

    addToSettingsList(key, value, size) {
        //Save it locally
        this.getOrFetch("/users/current-user/settings")
            .then(settings=>{
                var list = settings[key];
                if (!list) {
                    list = [];
                    settings[key] = list;
                }
                //Remove it if it's already in the list, so we don't get doubleups
                var index = list.indexOf(value);
                if (index > -1) {
                    list.splice(index, 1);
                }
                //Add it
                list.unshift(value);
                if (size && list.length > size) {
                    list.splice(size, list.length-size);
                }
                //Save it globally
                var data = {};
                data[key] = list;
                this.remote.put("/users/current-user/settings", data);

                //Trigger necessary events.
                this.settingWasMutated(key, list);
            });
    }

    removeFromSettingsList(key, value) {
        //Save it locally
        this.getOrFetch("/users/current-user/settings")
            .then(settings=>{
                var list = settings[key];
                if (!list) {
                    return;
                }
                //Remove it 
                var index = list.indexOf(value);
                if (index > -1) {
                    list.splice(index, 1);
                }
                //Save it globally
                var data = {};
                data[key] = list;
                this.remote.put("/users/current-user/settings", data);

                //Trigger necessary events.
                this.settingWasMutated(key, list);
            });
    }
   
    putSetting(key, value) {
        //Save it locally
        this.getOrFetch("/users/current-user/settings")
            .then(settings=>settings[key] = value);

        //Save it globally
        var data = {};
        data[key] = value;
        this.remote.put("/users/current-user/settings", data);

        //Trigger necessary events.
        this.settingWasMutated(key, value);
    }

    putSettings(data) {
        this.getOrFetch("/users/current-user/settings")
            .then(settings=>Object.assign(settings, data));
        
        this.remote.put("/users/current-user/settings", data);

        //Trigger necessary events.
        data.keys().forEach(key=>{
            this.settingWasMutated(key, data[key]);
        });
    }

    settingWasMutated(key, value) {
        var listenerList = this.settingsListeners[key];
        if (!listenerList) {
            return;
        }
        for (var i = listenerList.length-1; i>=0; i--) {
            listenerList[i](value);
        }
    }

    watchSetting(key, listener) {
        var listenerList = this.settingsListeners[key];
        if (!listenerList) {
            listenerList = [];
            this.settingsListeners[key] = listenerList;
        }
        listenerList.push(listener);
    }

    unwatchSetting(key, listener) {
        var listenerList = this.settingsListeners[key];
        if (!listenerList) {
            return;
        }
        this.settingsListeners[key] = listenerList.filter(x=>x!==listener);
    }

    //NON session stuff
    getPermanentlyStored(key) {
        return localStorage.getItem(key);
    }

    setPermanentlyStored(key, value) {
        localStorage.setItem(key, value);
    }
}

export default SessionStorage;