import React, { Component } from 'react';
import { Upload, FilePlus } from 'react-feather';

import FileDrop from 'react-file-drop';

import "./Dropzone.scss";

class Dropzone extends Component {
	render() {
		return (
			<FileDrop
				onDrop={this.props.disabled ? null : this.props.onDrop}
				onDragOver={(e) => {
					let event = e;
					event.stopPropagation();
					event.preventDefault();
				}}
				className={this.props.className}
			>
				<div className={`dropzone${this.props.uploading ? " uploading" : ""}${this.props.className ? " " + this.props.className : ""}${this.props.small ? " dropzone-small" : ""}${this.props.hidden ? " dropzone-hidden" : ""}${this.props.disabled ? " disabled-row" : ""}`} onClick={() => this.fileInput.click()}>
					{this.props.uploading ? (
						<div className="uploading-message">
							{this.props.showUploadingSpinner && <div className="sp-circle" />}
							{this.props.uploadingMessage}
						</div>
					) : (
						<>
							{!this.props.small && <Upload className="upload-icon" />}
							<div className="dropzone-message" style={{ display: 'flex', alignItems: 'center', gap: 10 }}>{this.props.small && <FilePlus className="upload-icon" size='1rem'/>}{this.props.displayMessage ? this.props.displayMessage : "Drop file here or click to upload"}</div>
							<input
								type="file"
								style={{ display: "none" }}
								onChange={e => { this.props.onDrop(e.target.files) }}
								ref={el => this.fileInput = el}
								multiple
								disabled={this.props.disabled}
							/>
						</>
					)}
				</div>
			</FileDrop>);
	}
}

export default Dropzone;